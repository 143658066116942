import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import Navbar from "./Navbar";
import NotFound from "../Pages/NotFound";

// Import components from Frameworks
import { Dashboard as FrameworksDashboard, AMF, FMF, AHMF } from "./Frameworks";

// Import components from MEL
import { Dashboard as MELDashboard, Outputs, Indicators, IPTT } from "./MEL";

// Import components from GIS
import { Maps, NewMap } from "./GIS";

// Import components from UtilityData
import {
  Agrovets,
  CommunityGroups,
  Farriers,
  CareClubs,
  EquineOwners,
  Practitioners,
} from "./UtilityData";

// Import other components
import Partners from "./Partners/Partners";
import Settings from "./Settings/Settings";
import Users from "./Users/Users";
import Home from "./Home/Home";
import Profile from "./Profile/Profile";

function PageRoutes() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Get user data from localStorage
    const token = localStorage.getItem("gdfhgfhtkn");
    const storedUser = localStorage.getItem("user");

    if (!token || !storedUser) {
      navigate("/");
    } else {
      try {
        const userData = JSON.parse(storedUser);
        setUser(userData);
      } catch (error) {
        console.error("Error parsing user data:", error);
        navigate("/");
      }
    }
    setLoading(false);
  }, [navigate]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar user={user} setUser={setUser} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 9 }}>
        <Routes>
          <Route path="/home" element={<Home user={user} />} />

          {/* Frameworks Routes */}
          <Route
            path="/frameworks/dashboard"
            element={<FrameworksDashboard />}
          />
          <Route path="/frameworks/amf" element={<AMF />} />
          <Route path="/frameworks/fmf" element={<FMF />} />
          <Route path="/frameworks/ahmf" element={<AHMF />} />

          {/* MEL Routes */}
          <Route path="/mel/dashboard" element={<MELDashboard />} />
          <Route path="/mel/outputs" element={<Outputs />} />
          <Route path="/mel/indicators" element={<Indicators />} />
          <Route path="/mel/iptt" element={<IPTT />} />

          {/* GIS Routes */}
          <Route path="/gis/maps" element={<Maps />} />
          <Route path="/gis/new" element={<NewMap />} />

          {/* Utility Data Routes */}
          <Route path="/utility/agrovets" element={<Agrovets />} />
          <Route
            path="/utility/community-groups"
            element={<CommunityGroups />}
          />
          <Route path="/utility/farriers" element={<Farriers />} />
          <Route path="/utility/care-clubs" element={<CareClubs />} />
          <Route path="/utility/equine-owners" element={<EquineOwners />} />
          <Route path="/utility/practitioners" element={<Practitioners />} />

          {/* Other Routes */}
          <Route path="/partners" element={<Partners />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/users" element={<Users />} />

          {/* 404 Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default PageRoutes;
