import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Stack,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Alert,
  Snackbar,
  CircularProgress,
  Grid,
  Autocomplete,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  LocationOn as LocationIcon,
  LocalHospital as LocalHospitalIcon,
} from "@mui/icons-material";
import LocationMap from "../common/LocationMap";

const Practitioners = () => {
  const [practitioners, setPractitioners] = useState([]);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingPractitioner, setEditingPractitioner] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [formData, setFormData] = useState({
    Name: "",
    Location: "",
    Latitude: "",
    Longitude: "",
    Contact: "",
    Partner: "",
    Email: "",
    Address: "",
    Specialization: "",
    Qualification: "",
    YearsOfExperience: "",
    Status: true,
  });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchPractitioners();
    fetchPartners();
  }, [page]);

  const fetchPartners = async () => {
    try {
      const response = await fetch("/api/partners");
      const data = await response.json();
      setPartners(data || []);
    } catch (error) {
      console.error("Error fetching partners:", error);
    }
  };

  const fetchPractitioners = async () => {
    try {
      const response = await fetch(
        `/api/practitioners/all/${page * rowsPerPage}`
      );
      const data = await response.json();
      setPractitioners(data.result || []);
    } catch (error) {
      console.error("Error fetching practitioners:", error);
      showSnackbar("Failed to load practitioners", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const url = editingPractitioner
        ? `/api/practitioners/${editingPractitioner.ID}`
        : "/api/practitioners/create";
      const method = editingPractitioner ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) throw new Error("Failed to save");

      showSnackbar(
        `Practitioner ${
          editingPractitioner ? "updated" : "created"
        } successfully`
      );
      setOpenDialog(false);
      fetchPractitioners();
    } catch (error) {
      console.error("Error saving practitioner:", error);
      showSnackbar("Failed to save practitioner", "error");
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this practitioner?"))
      return;

    try {
      const response = await fetch(`/api/practitioners/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) throw new Error("Failed to delete");

      showSnackbar("Practitioner deleted successfully");
      fetchPractitioners();
    } catch (error) {
      console.error("Error deleting practitioner:", error);
      showSnackbar("Failed to delete practitioner", "error");
    }
  };

  const handleOpenMap = (practitioner) => {
    setSelectedLocation(practitioner);
    setShowMap(true);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleEdit = (practitioner) => {
    setEditingPractitioner(practitioner);
    setFormData(practitioner);
    setOpenDialog(true);
  };

  const filteredData = practitioners.filter((item) =>
    Object.values(item)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ p: 3 }}>
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <Box
          sx={{
            p: 3,
            background: (theme) =>
              `linear-gradient(45deg, ${theme.palette.info.main}, ${theme.palette.info.dark})`,
            color: "white",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <LocalHospitalIcon sx={{ fontSize: 40 }} />
            <Box>
              <Typography variant="h5" fontWeight={600}>
                Practitioners Management
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 0.5, opacity: 0.8 }}>
                Manage and track all registered practitioners
              </Typography>
            </Box>
          </Stack>
        </Box>

        <TableContainer sx={{ p: 2 }}>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: 300 }}
            />
          </Box>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Designation</TableCell>
                  <TableCell>County</TableCell>
                  <TableCell>SubCounty</TableCell>
                  <TableCell>Ward</TableCell>
                  <TableCell>Associated To</TableCell>
                  <TableCell>Partner</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((practitioner) => (
                  <TableRow key={practitioner.ID}>
                    <TableCell>{practitioner.Name}</TableCell>
                    <TableCell>{practitioner.Contact}</TableCell>
                    <TableCell>{practitioner.Designation}</TableCell>
                    <TableCell>{practitioner.County}</TableCell>
                    <TableCell>{practitioner.SubCounty}</TableCell>
                    <TableCell>{practitioner.Ward}</TableCell>
                    <TableCell>{practitioner.AssociatedTo}</TableCell>
                    <TableCell>{practitioner.Partner}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="info"
                        onClick={() => handleOpenMap(practitioner)}
                        size="small"
                      >
                        <LocationIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleEdit(practitioner)}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(practitioner.ID)}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <TablePagination
            component="div"
            count={-1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            rowsPerPageOptions={[10]}
          />
        </TableContainer>
      </Card>

      {/* Map Dialog */}
      <Dialog
        open={showMap}
        onClose={() => setShowMap(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Location Map</DialogTitle>
        <DialogContent>
          {selectedLocation && (
            <Box sx={{ height: 400, width: "100%" }}>
              <LocationMap location={selectedLocation} />
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* Add/Edit Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingPractitioner ? "Edit Practitioner" : "Add New Practitioner"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {/* Form fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                fullWidth
                value={formData.Name}
                onChange={(e) =>
                  setFormData({ ...formData, Name: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={partners}
                getOptionLabel={(option) => option.Name}
                value={
                  partners.find((p) => p.Name === formData.Partner) || null
                }
                onChange={(_, newValue) =>
                  setFormData({ ...formData, Partner: newValue?.Name || "" })
                }
                renderInput={(params) => (
                  <TextField {...params} label="Partner" required />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Qualification"
                fullWidth
                value={formData.Qualification}
                onChange={(e) =>
                  setFormData({ ...formData, Qualification: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Years of Experience"
                fullWidth
                type="number"
                value={formData.YearsOfExperience}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    YearsOfExperience: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Specialization"
                fullWidth
                multiline
                rows={3}
                value={formData.Specialization}
                onChange={(e) =>
                  setFormData({ ...formData, Specialization: e.target.value })
                }
              />
            </Grid>
            {/* Location fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Latitude"
                fullWidth
                type="number"
                value={formData.Latitude}
                onChange={(e) =>
                  setFormData({ ...formData, Latitude: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Longitude"
                fullWidth
                type="number"
                value={formData.Longitude}
                onChange={(e) =>
                  setFormData({ ...formData, Longitude: e.target.value })
                }
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button onClick={() => setOpenDialog(false)} color="inherit">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!formData.Name || !formData.Partner}
          >
            {editingPractitioner ? "Save Changes" : "Add Practitioner"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Practitioners;
