import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const TrendTable = ({ data, metrics }) => {
  const [expandedYear, setExpandedYear] = useState(null);

  const getScoreColor = (value) => {
    if (value >= 75) return "success.main";
    if (value >= 50) return "warning.main";
    return "error.main";
  };

  const QuarterCard = ({ quarterData }) => (
    <Paper elevation={2} sx={{ p: 2 }}>
      {Object.entries(quarterData)
        .filter(([key]) => key !== "assessments")
        .map(([key, value]) => (
          <Box key={key} sx={{ mb: 1.5 }}>
            <Typography variant="body2" color="textSecondary">
              {key}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
              <Box
                sx={{
                  flexGrow: 1,
                  height: 8,
                  bgcolor: "grey.200",
                  borderRadius: 1,
                  mr: 1,
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: `${value}%`,
                    height: "100%",
                    bgcolor: getScoreColor(value),
                    borderRadius: "inherit",
                  }}
                />
              </Box>
              <Typography variant="body2" sx={{ minWidth: 40 }}>
                {value}%
              </Typography>
            </Box>
          </Box>
        ))}
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{ mt: 2, display: "block" }}
      >
        Based on {quarterData.assessments} assessment
        {quarterData.assessments !== 1 ? "s" : ""}
      </Typography>
    </Paper>
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Year</TableCell>
            <TableCell align="right">Assessments</TableCell>
            {metrics.map((metric) => (
              <TableCell key={metric.key} align="right">
                {metric.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((yearData) => (
            <React.Fragment key={yearData.year}>
              <TableRow>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setExpandedYear(
                        expandedYear === yearData.year ? null : yearData.year
                      )
                    }
                  >
                    {expandedYear === yearData.year ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell>{yearData.year}</TableCell>
                <TableCell align="right">
                  {yearData.total_assessments}
                </TableCell>
                {metrics.map((metric) => (
                  <TableCell key={metric.key} align="right">
                    <Typography
                      component="span"
                      sx={{
                        color: getScoreColor(yearData[`avg_${metric.key}`]),
                        fontWeight: "bold",
                      }}
                    >
                      {yearData[`avg_${metric.key}`]}%
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={metrics.length + 3}
                >
                  <Collapse
                    in={expandedYear === yearData.year}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Quarterly Breakdown
                      </Typography>
                      <Grid container spacing={2}>
                        {["Q1", "Q2", "Q3", "Q4"].map((quarter) => (
                          <Grid item xs={12} md={3} key={quarter}>
                            <Typography variant="subtitle1" gutterBottom>
                              {quarter}
                            </Typography>
                            <QuarterCard
                              quarterData={yearData.quarterly_data[quarter]}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TrendTable;
