import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Stack,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Alert,
  Snackbar,
  CircularProgress,
  Grid,
  Autocomplete,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  LocationOn as LocationIcon,
  Groups as GroupsIcon,
} from "@mui/icons-material";
import LocationMap from "../common/LocationMap";

const CommunityGroups = () => {
  const [communityGroups, setCommunityGroups] = useState([]);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingGroup, setEditingGroup] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [formData, setFormData] = useState({
    Name: "",
    Location: "",
    Latitude: "",
    Longitude: "",
    Contact: "",
    Partner: "",
    Email: "",
    Address: "",
    MemberCount: "",
    Purpose: "",
    MeetingFrequency: "",
    Status: true,
  });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchCommunityGroups();
    fetchPartners();
  }, [page]);

  const fetchPartners = async () => {
    try {
      const response = await fetch("/api/partners");
      const data = await response.json();
      setPartners(data || []);
    } catch (error) {
      console.error("Error fetching partners:", error);
    }
  };

  const fetchCommunityGroups = async () => {
    try {
      const response = await fetch(
        `/api/communitygroups/all/${page * rowsPerPage}`
      );
      const data = await response.json();
      setCommunityGroups(data.result || []);
    } catch (error) {
      console.error("Error fetching community groups:", error);
      showSnackbar("Failed to load community groups", "error");
    } finally {
      setLoading(false);
    }
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSubmit = async () => {
    try {
      const url = editingGroup
        ? `/api/communitygroups/${editingGroup.ID}`
        : "/api/communitygroups/create";
      const method = editingGroup ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) throw new Error("Failed to save");

      showSnackbar(
        `Community Group ${editingGroup ? "updated" : "created"} successfully`
      );
      setOpenDialog(false);
      fetchCommunityGroups();
    } catch (error) {
      console.error("Error saving community group:", error);
      showSnackbar("Failed to save community group", "error");
    }
  };

  const handleDelete = async (id) => {
    if (
      !window.confirm("Are you sure you want to delete this community group?")
    )
      return;

    try {
      const response = await fetch(`/api/communitygroups/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) throw new Error("Failed to delete");

      showSnackbar("Community Group deleted successfully");
      fetchCommunityGroups();
    } catch (error) {
      console.error("Error deleting community group:", error);
      showSnackbar("Failed to delete community group", "error");
    }
  };

  const handleOpenMap = (group) => {
    setSelectedLocation(group);
    setShowMap(true);
  };

  const handleEdit = (group) => {
    setEditingGroup(group);
    setFormData(group);
    setOpenDialog(true);
  };

  const filteredData = communityGroups.filter((item) =>
    Object.values(item)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ p: 3 }}>
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <Box
          sx={{
            p: 3,
            background: (theme) =>
              `linear-gradient(45deg, ${theme.palette.success.main}, ${theme.palette.success.dark})`,
            color: "white",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <GroupsIcon sx={{ fontSize: 40 }} />
            <Box>
              <Typography variant="h5" fontWeight={600}>
                Community Groups Management
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 0.5, opacity: 0.8 }}>
                Manage and track all registered community groups
              </Typography>
            </Box>
          </Stack>
        </Box>

        <TableContainer sx={{ p: 2 }}>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: 300 }}
            />
          </Box>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Group Name</TableCell>
                  <TableCell>Town</TableCell>
                  <TableCell>Total Members</TableCell>
                  <TableCell>Male</TableCell>
                  <TableCell>Female</TableCell>
                  <TableCell>Equines</TableCell>
                  <TableCell>Partner</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((group) => (
                  <TableRow key={group.ID}>
                    <TableCell>{group.GroupName}</TableCell>
                    <TableCell>{group.Town}</TableCell>
                    <TableCell>{group.Total_Members}</TableCell>
                    <TableCell>{group.Total_Male}</TableCell>
                    <TableCell>{group.Total_Female}</TableCell>
                    <TableCell>{group.Equines}</TableCell>
                    <TableCell>{group.Partner}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="info"
                        onClick={() => handleOpenMap(group)}
                        size="small"
                      >
                        <LocationIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleEdit(group)}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(group.ID)}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <TablePagination
            component="div"
            count={-1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            rowsPerPageOptions={[10]}
          />
        </TableContainer>
      </Card>

      {/* Map Dialog */}
      <Dialog
        open={showMap}
        onClose={() => setShowMap(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Location Map</DialogTitle>
        <DialogContent>
          {selectedLocation && (
            <Box sx={{ height: 400, width: "100%" }}>
              <LocationMap location={selectedLocation} />
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* Add/Edit Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingGroup ? "Edit Community Group" : "Add New Community Group"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {/* Form fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                fullWidth
                value={formData.Name}
                onChange={(e) =>
                  setFormData({ ...formData, Name: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={partners}
                getOptionLabel={(option) => option.Name}
                value={
                  partners.find((p) => p.Name === formData.Partner) || null
                }
                onChange={(_, newValue) =>
                  setFormData({ ...formData, Partner: newValue?.Name || "" })
                }
                renderInput={(params) => (
                  <TextField {...params} label="Partner" required />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Member Count"
                fullWidth
                type="number"
                value={formData.MemberCount}
                onChange={(e) =>
                  setFormData({ ...formData, MemberCount: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Meeting Frequency"
                fullWidth
                value={formData.MeetingFrequency}
                onChange={(e) =>
                  setFormData({ ...formData, MeetingFrequency: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Purpose"
                fullWidth
                multiline
                rows={3}
                value={formData.Purpose}
                onChange={(e) =>
                  setFormData({ ...formData, Purpose: e.target.value })
                }
              />
            </Grid>
            {/* Location fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Latitude"
                fullWidth
                type="number"
                value={formData.Latitude}
                onChange={(e) =>
                  setFormData({ ...formData, Latitude: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Longitude"
                fullWidth
                type="number"
                value={formData.Longitude}
                onChange={(e) =>
                  setFormData({ ...formData, Longitude: e.target.value })
                }
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button onClick={() => setOpenDialog(false)} color="inherit">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!formData.Name || !formData.Partner}
          >
            {editingGroup ? "Save Changes" : "Add Community Group"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CommunityGroups;
