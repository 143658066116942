import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Grid,
  DialogActions,
  Pagination,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Divider,
  Chip,
  Card,
  CardContent,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import FlagIcon from "@mui/icons-material/Flag";
import DescriptionIcon from "@mui/icons-material/Description";
import TimelineIcon from "@mui/icons-material/Timeline";
import AddIcon from "@mui/icons-material/Add";
import styled from "@emotion/styled";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  padding: theme.spacing(2),
  transition: "transform 0.2s, box-shadow 0.2s",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: theme.shadows[4],
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Loading = () => (
  <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
    <CircularProgress />
  </Box>
);

const OutputDialog = ({ open, handleClose, output }) => {
  const [indicators, setIndicators] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchIndicators = async () => {
      if (!output?.ID) return;
      setLoading(true);
      try {
        const response = await fetch(`/api/indicators/output/${output.ID}/0`);
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        console.log(data);

        setIndicators(data.data || []);
      } catch (error) {
        console.error("Error fetching indicators:", error);
        setIndicators([]);
      } finally {
        setLoading(false);
      }
    };
    fetchIndicators();
  }, [output?.ID]);

  if (!output) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 1,
          boxShadow: "0 4px 16px rgba(0,0,0,0.1)",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "primary.main",
          color: "white",
          py: 1.5,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <DescriptionIcon />
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            {output.Description}
          </Typography>
        </Box>
        <IconButton onClick={handleClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 2 }}>
        {/* Hierarchy Path */}
        <Box sx={{ mb: 2, p: 1.5, bgcolor: "grey.50", borderRadius: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {output.hierarchy?.Goal} → {output.hierarchy?.SO} →{" "}
            {output.hierarchy?.IO}
          </Typography>
        </Box>

        {/* Indicators Section */}
        <Box>
          <Typography
            variant="subtitle1"
            color="primary"
            sx={{ mb: 1.5, fontWeight: 500 }}
          >
            Indicators{" "}
            {loading && (
              <CircularProgress
                size={16}
                sx={{ ml: 1, verticalAlign: "middle" }}
              />
            )}
          </Typography>

          {loading ? (
            <Box sx={{ textAlign: "center", py: 2 }}>
              <CircularProgress size={20} />
              <Typography
                variant="body2"
                sx={{ mt: 1, color: "text.secondary" }}
              >
                Loading indicators...
              </Typography>
            </Box>
          ) : indicators.length > 0 ? (
            <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
              {indicators.map((indicator, index) => (
                <Card
                  key={index}
                  variant="outlined"
                  sx={{
                    mb: 1.5,
                    "&:last-child": { mb: 0 },
                  }}
                >
                  <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                        Indicator {indicator.Number}
                      </Typography>
                      <Chip
                        label={indicator.Type || "Quantitative"}
                        size="small"
                        variant="outlined"
                        sx={{ height: 24 }}
                      />
                    </Box>

                    <Typography variant="body2" sx={{ mb: 1.5 }}>
                      {indicator.Description}
                    </Typography>

                    <Grid container spacing={2} sx={{ mt: 0.5 }}>
                      <Grid item xs={6}>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          display="block"
                        >
                          Baseline
                        </Typography>
                        <Typography variant="body2">
                          {indicator.Baseline || "Not set"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          display="block"
                        >
                          Target
                        </Typography>
                        <Typography variant="body2">
                          {indicator.Target || "Not set"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Box>
          ) : (
            <Box sx={{ textAlign: "center", py: 2, color: "text.secondary" }}>
              <Typography variant="body2">
                No indicators available for this output
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const Outputs = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedOutput, setSelectedOutput] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [partnerName, setPartnerName] = useState("");
  const [goals, setGoals] = useState([]);
  const [sos, setSos] = useState([]);
  const [ios, setIos] = useState([]);
  const [selectedGoalID, setSelectedGoalID] = useState(null);
  const [selectedStrategicID, setSelectedStrategicID] = useState(null);
  const [selectedIntermediateID, setSelectedIntermediateID] = useState(null);
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [offset, setOffset] = useState(0);
  const [editDialog, setEditDialog] = useState(false);
  const [editingOutput, setEditingOutput] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteOutputId, setDeleteOutputId] = useState(null);
  const [updateConfirmOpen, setUpdateConfirmOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    try {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        try {
          const userData = JSON.parse(storedUser);
          const partner = userData?.Partner || userData?.partner;

          if (partner) {
            setPartnerName(partner);
          } else {
            console.error("No partner found in user data:", userData);
          }
        } catch (error) {
          console.error("Error parsing user data:", error);
        }
      } else {
        console.log("No user data found in localStorage");
      }
    } catch (error) {
      console.error("Error handling user data:", error);
    }
  }, []);

  useEffect(() => {}, [partnerName]);

  const fetchOutputs = useCallback(async () => {
    if (!partnerName) {
      console.log("Skipping fetchOutputs - no partner name available");
      return;
    }

    console.log("fetchOutputs called for partner:", partnerName);
    setLoading(true);
    try {
      const url = `/api/outputs/all/${encodeURIComponent(
        partnerName
      )}/${offset}`;
      console.log("Fetching from URL:", url);

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Outputs result:", result);

      if (Array.isArray(result.data)) {
        const enrichedOutputs = await Promise.all(
          result.data.map(async (output) => {
            try {
              const hierarchyResponse = await fetch(
                `/api/outputs/joined/${output.ID}`,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  credentials: "include",
                }
              );

              if (!hierarchyResponse.ok) {
                throw new Error(
                  `HTTP error! status: ${hierarchyResponse.status}`
                );
              }

              const hierarchyData = await hierarchyResponse.json();
              return {
                ...output,
                hierarchy: hierarchyData.data[0] || {},
              };
            } catch (error) {
              console.error(
                `Error fetching hierarchy for output ${output.ID}:`,
                error
              );
              return {
                ...output,
                hierarchy: {},
              };
            }
          })
        );

        console.log("Setting enriched outputs:", enrichedOutputs);
        setData({
          data: enrichedOutputs,
          total: result.total,
        });
      } else {
        console.log("No data in result or data is not an array");
        setData({ data: [], total: 0 });
      }
    } catch (error) {
      console.error("Error in fetchOutputs:", error);
      setData({ data: [], total: 0 });
    } finally {
      setLoading(false);
    }
  }, [partnerName, offset]);

  useEffect(() => {
    if (partnerName) {
      console.log("Fetching outputs for partner:", partnerName);
      fetchOutputs();
      fetchGoals();
    } else {
      console.log("No partner name available yet");
    }
  }, [partnerName, fetchOutputs]);

  const fetchGoals = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/goals");
      const data = await response.json();
      setGoals(data.data || []);
      if (data.data?.length > 0) {
        setSelectedGoalID(data.data[0].ID);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching goals:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedGoalID) {
      fetchSOs(selectedGoalID);
    }
  }, [selectedGoalID]);

  const fetchSOs = async (goalId) => {
    try {
      const response = await fetch(`/api/so/goal/${goalId}`);
      const data = await response.json();
      setSos(data.data || []);
      if (data.data?.length > 0) {
        setSelectedStrategicID(data.data[0].ID);
      }
    } catch (error) {
      console.error("Error fetching SOs:", error);
    }
  };

  useEffect(() => {
    if (selectedStrategicID) {
      fetchIOs(selectedStrategicID);
    }
  }, [selectedStrategicID]);

  const fetchIOs = async (soId) => {
    try {
      const response = await fetch(`/api/io/so/${soId}`);
      const data = await response.json();
      setIos(data.data || []);
      if (data.data?.length > 0) {
        setSelectedIntermediateID(data.data[0].ID);
      }
    } catch (error) {
      console.error("Error fetching IOs:", error);
    }
  };

  const handleSubmit = async () => {
    if (!description || !selectedIntermediateID) {
      setError("Please fill in all required fields");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch("/api/outputs/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          Partner: partnerName,
          Intermediate_ID: selectedIntermediateID,
          Description: description,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setOpenDialog(false);
        await fetchOutputs();
        setDescription("");
        setError("");
        setSuccessMessage("Output created successfully");
      } else {
        setError(data.error || "Failed to create output");
      }
    } catch (error) {
      console.error("Error creating output:", error);
      setError("Failed to create output");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (outputId) => {
    setDeleteOutputId(outputId);
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/outputs/${deleteOutputId}`, {
        method: "DELETE",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await fetchOutputs();
      setDeleteConfirmOpen(false);
      setSuccessMessage("Output deleted successfully");
    } catch (error) {
      console.error("Error deleting output:", error);
      setError("Failed to delete output");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    if (!description || !selectedIntermediateID) {
      setError("Please fill in all required fields");
      return;
    }
    setUpdateConfirmOpen(true);
  };

  const confirmUpdate = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/outputs/${editingOutput.ID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          Partner: partnerName,
          Intermediate_ID: selectedIntermediateID,
          Description: description,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setEditDialog(false);
        setEditingOutput(null);
        setUpdateConfirmOpen(false);
        await fetchOutputs();
        setDescription("");
        setError("");
        setSuccessMessage("Output updated successfully");
      } else {
        setError(data.error || "Failed to update output");
      }
    } catch (error) {
      console.error("Error updating output:", error);
      setError("Failed to update output");
    } finally {
      setLoading(false);
    }
  };

  console.log("Rendering with data:", data);

  const handleCloseSnackbar = () => {
    setSuccessMessage("");
  };

  return (
    <Box sx={{ p: 3, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          backgroundColor: "white",
          p: 2,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        <Box>
          <Typography variant="h5" fontWeight={500}>
            {partnerName === "Brooke East Africa"
              ? "All Outputs"
              : `${partnerName} Outputs`}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Manage and track all output activities
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={() => setOpenDialog(true)}
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: (theme) => theme.palette.success.main,
            "&:hover": {
              backgroundColor: (theme) => theme.palette.success.dark,
            },
          }}
        >
          New Output
        </Button>
      </Box>

      {/* Outputs Grid */}
      <StyledGrid container spacing={2}>
        {data?.data?.map((output) => (
          <Grid item xs={12} md={6} key={output.ID}>
            <StyledPaper>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    flex: 1,
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedOutput(output)}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight={500}
                    gutterBottom
                    sx={{ color: "primary.main" }}
                  >
                    Output {output.Number || "#"}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {output.Description}
                  </Typography>

                  <Box
                    sx={{ display: "flex", flexWrap: "wrap", gap: 2, mb: 1 }}
                  >
                    <Chip
                      size="small"
                      label={`Goal: ${output.hierarchy?.Goal || "N/A"}`}
                      sx={{ backgroundColor: "primary.light", color: "white" }}
                    />
                    <Chip
                      size="small"
                      label={`SO: ${output.hierarchy?.SO || "N/A"}`}
                      sx={{ backgroundColor: "secondary.light" }}
                    />
                    <Chip
                      size="small"
                      label={`IO: ${output.hierarchy?.IO || "N/A"}`}
                      variant="outlined"
                    />
                  </Box>

                  {partnerName === "Brooke East Africa" && (
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ display: "block", mt: 1 }}
                    >
                      Partner: {output.Partner}
                    </Typography>
                  )}
                </Box>

                {partnerName === "Brooke East Africa" && (
                  <Box sx={{ display: "flex", gap: 0.5 }}>
                    <Tooltip title="Edit Output">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingOutput(output);
                          setDescription(output.Description);
                          setSelectedIntermediateID(output.Intermediate_ID);
                          setEditDialog(true);
                        }}
                        sx={{ color: "primary.main" }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Output">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(output.ID);
                        }}
                        sx={{ color: "error.main" }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Box>
            </StyledPaper>
          </Grid>
        ))}
      </StyledGrid>

      {/* Pagination */}
      {data?.total > 0 && (
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
            p: 2,
            borderRadius: 1,
            boxShadow: 1,
          }}
        >
          <Pagination
            count={Math.ceil(data.total / 10)}
            page={offset + 1}
            onChange={(event, newPage) => {
              setOffset(newPage - 1);
              window.scrollTo(0, 0);
            }}
            color="primary"
            showFirstButton
            showLastButton
            size="medium"
          />
        </Box>
      )}

      {/* Empty State */}
      {(!data?.data || data.data.length === 0) && !loading && (
        <Box
          sx={{
            textAlign: "center",
            py: 8,
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 1,
          }}
        >
          <Typography variant="h6" color="text.secondary" gutterBottom>
            No outputs found
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Click the "New Output" button to create your first output
          </Typography>
        </Box>
      )}

      {/* Loading State */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 200,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {selectedOutput && (
        <OutputDialog
          open={Boolean(selectedOutput)}
          handleClose={() => setSelectedOutput(null)}
          output={selectedOutput}
        />
      )}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create New Output</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Goal</InputLabel>
              <Select
                value={selectedGoalID || ""}
                onChange={(e) => setSelectedGoalID(e.target.value)}
                label="Goal"
              >
                {goals.map((goal) => (
                  <MenuItem key={goal.ID} value={goal.ID}>
                    {goal.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Strategic Outcome</InputLabel>
              <Select
                value={selectedStrategicID || ""}
                onChange={(e) => setSelectedStrategicID(e.target.value)}
                label="Strategic Outcome"
              >
                {sos.map((so) => (
                  <MenuItem key={so.ID} value={so.ID}>
                    {so.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Intermediate Outcome</InputLabel>
              <Select
                value={selectedIntermediateID || ""}
                onChange={(e) => setSelectedIntermediateID(e.target.value)}
                label="Intermediate Outcome"
              >
                {ios.map((io) => (
                  <MenuItem key={io.ID} value={io.ID}>
                    {io.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Output Description"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
                mt: 2,
              }}
            >
              <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={loading}
              >
                Create
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={editDialog}
        onClose={() => {
          setEditDialog(false);
          setEditingOutput(null);
          setDescription("");
          setError("");
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Output</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Goal</InputLabel>
              <Select
                value={selectedGoalID || ""}
                onChange={(e) => setSelectedGoalID(e.target.value)}
                label="Goal"
              >
                {goals.map((goal) => (
                  <MenuItem key={goal.ID} value={goal.ID}>
                    {goal.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Strategic Outcome</InputLabel>
              <Select
                value={selectedStrategicID || ""}
                onChange={(e) => setSelectedStrategicID(e.target.value)}
                label="Strategic Outcome"
              >
                {sos.map((so) => (
                  <MenuItem key={so.ID} value={so.ID}>
                    {so.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Intermediate Outcome</InputLabel>
              <Select
                value={selectedIntermediateID || ""}
                onChange={(e) => setSelectedIntermediateID(e.target.value)}
                label="Intermediate Outcome"
              >
                {ios.map((io) => (
                  <MenuItem key={io.ID} value={io.ID}>
                    {io.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Output Description"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditDialog(false);
              setEditingOutput(null);
              setDescription("");
              setError("");
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleEdit} disabled={loading}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this output? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button
            onClick={confirmDelete}
            color="error"
            variant="contained"
            disabled={loading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={updateConfirmOpen}
        onClose={() => setUpdateConfirmOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to update this output?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateConfirmOpen(false)}>Cancel</Button>
          <Button
            onClick={confirmUpdate}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          width: "auto",
          maxWidth: "80%",
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{
            width: "100%",
            minWidth: "300px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Outputs;
