import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OSM from "ol/source/OSM";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import { Style, Circle, Fill, Stroke } from "ol/style";
import "ol/ol.css";

const Maps = () => {
  const navigate = useNavigate();
  const [maps, setMaps] = useState([]);
  const [selectedMap, setSelectedMap] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const mapRef = useRef();
  const mapElement = useRef();
  const [map, setMap] = useState(null);

  useEffect(() => {
    fetchMaps();
  }, []);

  const fetchMaps = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/gis/all/0");
      if (!response.ok) {
        throw new Error("Failed to fetch maps");
      }
      const data = await response.json();
      setMaps(data.result || []);
    } catch (error) {
      console.error("Error fetching maps:", error);
      setError(error.message);
      showSnackbar(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (mapId) => {
    try {
      const response = await fetch(`/api/gis/${mapId}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete map");
      }

      showSnackbar("Map deleted successfully", "success");
      fetchMaps(); // Refresh the list
    } catch (error) {
      console.error("Error deleting map:", error);
      showSnackbar(error.message, "error");
    }
  };

  const handleMapClick = (map) => {
    try {
      const layerData = JSON.parse(map.URL);
      setSelectedMap({ ...map, layerData });
    } catch (error) {
      console.error("Error parsing map data:", error);
      showSnackbar("Error loading map data", "error");
    }
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  useEffect(() => {
    if (selectedMap && mapElement.current) {
      const initialMap = new Map({
        target: mapElement.current,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          center: fromLonLat([0, 0]),
          zoom: 2,
        }),
      });

      setMap(initialMap);

      // Add vector layers for the data
      if (selectedMap.layerData) {
        Object.entries(selectedMap.layerData).forEach(
          ([datasetName, items]) => {
            const validItems = items.filter(
              (item) =>
                item.Latitude != null &&
                item.Longitude != null &&
                !isNaN(item.Latitude) &&
                !isNaN(item.Longitude)
            );

            if (validItems.length === 0) return;

            const vectorSource = new VectorSource({
              features: validItems.map((item) => {
                const feature = new Feature({
                  geometry: new Point(
                    fromLonLat([item.Longitude, item.Latitude])
                  ),
                  name: item.Name,
                  dataset: datasetName,
                  location: item.Location || item.Address,
                });
                return feature;
              }),
            });

            const vectorLayer = new VectorLayer({
              source: vectorSource,
              style: new Style({
                image: new Circle({
                  radius: 6,
                  fill: new Fill({
                    color: "#FF5733", // You can customize colors per dataset
                  }),
                  stroke: new Stroke({
                    color: "#fff",
                    width: 2,
                  }),
                }),
              }),
            });

            initialMap.addLayer(vectorLayer);
          }
        );
      }

      return () => {
        if (map) {
          map.setTarget(null);
        }
      };
    }
  }, [selectedMap]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h5">GIS Maps</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate("/gis/new")}
        >
          New Map
        </Button>
      </Stack>

      <Grid container spacing={3}>
        {maps.map((map) => (
          <Grid item xs={12} sm={6} md={4} key={map.ID}>
            <Card sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                {map.Title}
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {map.Description}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Category: {map.Category}
              </Typography>
              <Stack direction="row" spacing={1} mt={2}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleMapClick(map)}
                >
                  View
                </Button>
                <IconButton
                  size="small"
                  onClick={() => navigate(`/gis/edit/${map.ID}`)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton size="small" onClick={() => handleDelete(map.ID)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={Boolean(selectedMap)}
        onClose={() => setSelectedMap(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedMap?.Title}
          <IconButton
            onClick={() => setSelectedMap(null)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedMap && (
            <Box
              ref={mapElement}
              sx={{
                height: 400,
                width: "100%",
                "& .ol-control": {
                  background: "rgba(255,255,255,0.4)",
                  padding: "3px",
                  borderRadius: "4px",
                },
                "& .ol-zoom": {
                  top: ".5em",
                  left: ".5em",
                },
              }}
            />
          )}
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Maps;
