import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Paper,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Visibility as VisibilityIcon, Refresh } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

const Assessments = ({ partner, start, end, title = "AMF", onDataLoad }) => {
  // Helper functions first
  const getCurrentFinancialYear = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    return currentMonth < 3 ? today.getFullYear() - 1 : today.getFullYear();
  };

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear - 3; i <= currentYear + 3; i++) {
      years.push(i);
    }
    return years;
  };

  // Then state declarations
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [year, setYear] = useState(getCurrentFinancialYear().toString());
  const [activeQuarter, setActiveQuarter] = useState(year);
  const [dateRange, setDateRange] = useState({
    start: start,
    end: end,
  });

  // Reset dates function
  const resetDates = () => {
    const today = new Date();
    const currentMonth = today.getMonth();

    let currentQuarter;
    if (currentMonth >= 3 && currentMonth <= 5) currentQuarter = "Q1";
    else if (currentMonth >= 6 && currentMonth <= 8) currentQuarter = "Q2";
    else if (currentMonth >= 9 && currentMonth <= 11) currentQuarter = "Q3";
    else currentQuarter = "Q4";

    setYear(getCurrentFinancialYear().toString());
    setActiveQuarter(currentQuarter);
  };

  // Determine the tool based on the title
  const getTool = () => {
    switch (title) {
      case "AMF":
        return "Agrovets";
      case "AHMF":
        return "Practitioners";
      case "FMF":
        return "Farriers";
      default:
        return "Agrovets";
    }
  };

  // Function to fetch data from API
  const fetchData = async () => {
    if (!partner || !start || !end) return;

    setLoading(true);
    setError(null);
    try {
      const offset = page * pageSize;
      const response = await fetch(
        `/api/${getTool().toLowerCase()}/monitoring/all/${partner}/${start}/${end}/${offset}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result);

      if (Array.isArray(result?.data)) {
        setData(result.data);
        setTotalRows(result.total || result.data.length);
        if (onDataLoad) {
          onDataLoad(result.data);
        }
      } else {
        setError("Data format is incorrect");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [partner, start, end, page, pageSize]);

  // Main table columns (fewer columns)
  const getColumns = () => {
    switch (title) {
      case "AMF":
        return [
          {
            field: "Agrovet_Name",
            headerName: "Agrovet Name",
            flex: 1,
            minWidth: 180,
          },
          {
            field: "Location",
            headerName: "Location",
            flex: 1,
            minWidth: 150,
          },
          {
            field: "Assessment_Date",
            headerName: "Date",
            flex: 1,
            minWidth: 110,
          },
          {
            field: "Reporting_Quarter",
            headerName: "Quarter",
            flex: 1,
            minWidth: 100,
          },
          {
            field: "actions",
            headerName: "Details",
            width: 100,
            renderCell: (params) => (
              <IconButton
                onClick={() => {
                  setSelectedAssessment(params.row);
                  setOpenDialog(true);
                }}
                size="small"
              >
                <VisibilityIcon />
              </IconButton>
            ),
          },
        ];
      case "FMF":
        return [
          {
            field: "FarrierName",
            headerName: "Farrier Name",
            flex: 1,
            minWidth: 180,
          },
          {
            field: "Farrier_ID",
            headerName: "Farrier ID",
            flex: 1,
            minWidth: 130,
          },
          {
            field: "ALC_Site",
            headerName: "Site",
            flex: 1,
            minWidth: 150,
          },
          {
            field: "Date",
            headerName: "Date",
            flex: 1,
            minWidth: 110,
          },
          {
            field: "Quarter",
            headerName: "Quarter",
            flex: 1,
            minWidth: 100,
          },
          {
            field: "actions",
            headerName: "Details",
            width: 100,
            renderCell: (params) => (
              <IconButton
                onClick={() => {
                  setSelectedAssessment(params.row);
                  setOpenDialog(true);
                }}
                size="small"
              >
                <VisibilityIcon />
              </IconButton>
            ),
          },
        ];
      case "AHMF":
        return [
          {
            field: "AHP_Name",
            headerName: "Practitioner Name",
            flex: 1,
            minWidth: 180,
          },
          {
            field: "AHP_ID",
            headerName: "Practitioner ID",
            flex: 1,
            minWidth: 130,
          },
          {
            field: "Location",
            headerName: "Location",
            flex: 1,
            minWidth: 150,
          },
          {
            field: "Date",
            headerName: "Date",
            flex: 1,
            minWidth: 110,
          },
          {
            field: "Quarter",
            headerName: "Quarter",
            flex: 1,
            minWidth: 100,
          },
          {
            field: "actions",
            headerName: "Details",
            width: 100,
            renderCell: (params) => (
              <IconButton
                onClick={() => {
                  setSelectedAssessment(params.row);
                  setOpenDialog(true);
                }}
                size="small"
              >
                <VisibilityIcon />
              </IconButton>
            ),
          },
        ];
      default:
        return [];
    }
  };

  // Function to render assessment details in dialog
  const renderAssessmentDetails = () => {
    if (!selectedAssessment) return null;

    const getAMFFieldGroups = () => ({
      "Basic Information": [
        { label: "Agrovet Name", field: "Agrovet_Name" },
        { label: "Location", field: "Location" },
        { label: "Assessment Date", field: "Assessment_Date", isDate: true },
        { label: "Reporting Quarter", field: "Reporting_Quarter" },
        { label: "Reporting Year", field: "Reporting_Year" },
        { label: "Assessor", field: "Assessor" },
        { label: "Partner", field: "Partner" },
      ],
      "Legal Compliance": [
        { label: "Education", field: "Q01_Education", isYesNo: true },
        {
          label: "Registered Practitioner",
          field: "Q02_RegAgrovetPractitioner",
          isYesNo: true,
        },
        {
          label: "Registered Premises",
          field: "Q03_RegAgrovetPremises",
          isYesNo: true,
        },
        {
          label: "Pest Control Registration",
          field: "Q04_RegPestControl",
          isYesNo: true,
        },
      ],
      "Pharmacological Expert": [
        {
          label: "Medicine Storage",
          field: "Q33_MedicineStorage",
          isYesNo: true,
        },
        {
          label: "Medicine Technique",
          field: "Medicine_Technique",
          isYesNo: true,
        },
        {
          label: "Dose Calculation",
          field: "Q21_DoseCalculation",
          isYesNo: true,
        },
        {
          label: "Administration Route",
          field: "Q22_RouteOfAdministration",
          isYesNo: true,
        },
      ],
      "Content of Agrovet": [
        {
          label: "Equipment & Disposables",
          field: "Q34_EquipmentDisposables",
          isYesNo: true,
        },
        {
          label: "Product Display",
          field: "Q35_ProductDisplay",
          isYesNo: true,
        },
        {
          label: "Available Products",
          field: "Available_Products",
          isYesNo: true,
        },
      ],
      "Agrovet Governance": [
        {
          label: "Record Keeping",
          field: "Q37_PrescriptionRecords",
          isYesNo: true,
        },
        {
          label: "Waste Management",
          field: "Q36_SharpsWasteManagements",
          isYesNo: true,
        },
        {
          label: "Professional Conduct",
          field: "Q15_GoodConduct",
          isYesNo: true,
        },
      ],
    });

    const getAHMFFieldGroups = () => ({
      "Basic Information": [
        { label: "Practitioner Name", field: "AHP_Name" },
        { label: "Practitioner ID", field: "AHP_ID" },
        { label: "Type", field: "AhpType" },
        { label: "Location", field: "Location" },
        { label: "Assessment Number", field: "Assesment_No" },
        { label: "Date", field: "Date", isDate: true },
        { label: "Quarter", field: "Quarter" },
        { label: "Assessor", field: "Assessor" },
      ],
      "Legal Compliance": [
        {
          label: "Professional Registration",
          field: "ProfessionalReg",
          isYesNo: true,
        },
        { label: "License Status", field: "License", isYesNo: true },
        {
          label: "Proper Documentation",
          field: "Documentation",
          isYesNo: true,
        },
      ],
      "Clinical Expert": [
        { label: "History Taking", field: "HistoryTaking", isYesNo: true },
        {
          label: "Distance Observation",
          field: "DistanceObservation",
          isYesNo: true,
        },
        { label: "Diagnosis", field: "Diagnosis", isYesNo: true },
        { label: "Treatment", field: "Treatment", isYesNo: true },
        { label: "Pain Recognition", field: "PainRecognition", isYesNo: true },
      ],
      "Kit Content": [
        { label: "Kit Contents", field: "Kit_Contents", isYesNo: true },
        {
          label: "Equipment & Disposables",
          field: "Equipment_Disposables",
          isYesNo: true,
        },
        { label: "Medicine Storage", field: "MedicineStorage", isYesNo: true },
      ],
      "Clinical Governance": [
        { label: "Record Keeping", field: "RecordKeeping", isYesNo: true },
        { label: "Sharps Management", field: "Sharps", isYesNo: true },
        { label: "Cleanliness", field: "Cleanliness", isYesNo: true },
      ],
    });

    const getFMFFieldGroups = () => ({
      "Basic Information": [
        { label: "Farrier Name", field: "FarrierName" },
        { label: "Farrier ID", field: "Farrier_ID" },
        { label: "Site", field: "ALC_Site" },
        { label: "Date", field: "Date", isDate: true },
        { label: "Quarter", field: "Quarter" },
        { label: "Assessor", field: "Assessor" },
      ],
      "Legal Compliance": [
        {
          label: "Professional Registration",
          field: "ProfessionalReg",
          isYesNo: true,
        },
        { label: "Safety Awareness", field: "Safety", isYesNo: true },
        { label: "Safe Working", field: "SafeWorking", isYesNo: true },
      ],
      "Farriery Expert": [
        {
          label: "Static Examination",
          field: "StaticExamination",
          isYesNo: true,
        },
        {
          label: "Dynamic Examination",
          field: "DynamicExamination",
          isYesNo: true,
        },
        { label: "Hoof Balance", field: "HoofBalance", isYesNo: true },
        { label: "Trimming", field: "Trimming", isYesNo: true },
        { label: "Shoeing", field: "Shoeing", isYesNo: true },
      ],
      "Farrier Kit": [
        { label: "Kit Tools", field: "KitTools", isYesNo: true },
        { label: "Kit Maintenance", field: "KitMaintenance", isYesNo: true },
        { label: "Kit Nails", field: "KitNails", isYesNo: true },
        { label: "Kit Shoes", field: "KitShoes", isYesNo: true },
      ],
      Governance: [
        { label: "Record Keeping", field: "RecordKeeping", isYesNo: true },
        {
          label: "Professional Conduct",
          field: "Professionalism",
          isYesNo: true,
        },
        { label: "Animal Welfare", field: "AnimalComfort", isYesNo: true },
      ],
    });

    // Use different field groups based on the framework type
    const fieldGroups =
      title === "FMF"
        ? getFMFFieldGroups()
        : title === "AHMF"
        ? getAHMFFieldGroups()
        : getAMFFieldGroups();

    const formatValue = (value, isDate, isYesNo) => {
      if (value === null || value === undefined) return "-";
      if (isDate) return new Date(value).toLocaleDateString();
      if (isYesNo) return value === "YES" ? "Yes" : "No";
      return value.toString();
    };

    return (
      <Box sx={{ p: 2 }}>
        {Object.entries(fieldGroups).map(([groupName, fields]) => (
          <Box key={groupName} sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ mb: 2, color: "primary.main" }}>
              {groupName}
            </Typography>
            <Grid container spacing={2}>
              {fields.map((field) => (
                <Grid item xs={12} sm={6} md={4} key={field.field}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {field.label}
                    </Typography>
                    <Typography variant="body1">
                      {formatValue(
                        selectedAssessment[field.field],
                        field.isDate,
                        field.isYesNo
                      )}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Box>
    );
  };

  const filteredData = data.filter((row) => {
    const nameField =
      title === "AMF"
        ? "Agrovet_Name"
        : title === "AHMF"
        ? "AHP_Name"
        : "FarrierName";
    return (row[nameField] || "").toLowerCase().includes(filter.toLowerCase());
  });

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      {/* Only Name Filter */}
      <Box sx={{ mb: 3 }}>
        <TextField
          label="Filter by Name"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ width: 250 }}
        />
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box sx={{ p: 3, color: "error.main" }}>
          <Typography>{error}</Typography>
        </Box>
      ) : (
        <DataGrid
          rows={filteredData}
          columns={getColumns()}
          pagination
          page={page}
          pageSize={pageSize}
          rowCount={totalRows}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          paginationMode="server"
          getRowId={(row) => row.ID}
          sx={{
            "& .MuiDataGrid-cell": { fontSize: "0.875rem" },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              fontWeight: 600,
            },
            border: 1,
            borderColor: "divider",
            "& .MuiDataGrid-cell:focus": { outline: "none" },
          }}
        />
      )}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              Assessment Details - {selectedAssessment?.Agrovet_Name}
            </Typography>
            <IconButton onClick={() => setOpenDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>{renderAssessmentDetails()}</DialogContent>
      </Dialog>
    </Box>
  );
};

export default Assessments;
