import React, { cloneElement, Fragment, useEffect, useState } from "react";
import {
  Home as HomeIcon,
  Assessment,
  Pets,
  LocationOn,
  People,
  Settings,
  BarChart,
  Assignment,
  Business,
  LocalHospital,
  Store,
  Groups,
  School,
  Build,
  ExpandLess,
  ExpandMore,
  ChevronLeft,
  ChevronRight,
  Dashboard,
  MonitorHeart,
  Analytics,
  Insights,
  DataUsage,
  Timeline,
  AccountTree,
  Logout as LogoutIcon,
  PersonAdd as PersonAddIcon,
  AccountCircle as ProfileIcon,
} from "@mui/icons-material";
import { Money } from "@phosphor-icons/react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box } from "@mui/material";
import Header from "./Header/Header";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  padding: theme.spacing(1, 0, 1, 1),
  backgroundColor: "#fff",
  color: "#2596be",
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflowY: "hidden", // Disable vertical scrollbar
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Navbar = ({ user, setUser }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [openSections, setOpenSections] = useState({
    customerService: false,
    riskManagement: false,
    debtCollection: false,
  });
  const [menuItems, setMenuItems] = useState([]);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleToggle = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const logout = () => {
    fetch("/api/auth/logout", {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then(() => {
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {
        // Still clear and redirect on error
        localStorage.clear();
        window.location.href = "/";
      });
  };

  const adminItems = [
    { text: "Home", icon: <HomeIcon />, path: "/home" },
    {
      text: "Frameworks",
      icon: <Assessment />,
      subItems: [
        {
          text: "Agrovets Monitoring",
          path: "/frameworks/amf",
          icon: <MonitorHeart />,
        },
        {
          text: "Farriers Monitoring",
          path: "/frameworks/fmf",
          icon: <Analytics />,
        },
        {
          text: "Animal Health",
          path: "/frameworks/ahmf",
          icon: <Pets />,
        },
      ],
    },
    {
      text: "MEL",
      icon: <Insights />,
      subItems: [
        // {
        //   text: "Dashboard",
        //   path: "/mel/dashboard",
        //   icon: <Dashboard />,
        // },
        {
          text: "Outputs",
          path: "/mel/outputs",
          icon: <Timeline />,
        },
        {
          text: "Indicators",
          path: "/mel/indicators",
          icon: <DataUsage />,
        },
        {
          text: "IPTT",
          path: "/mel/iptt",
          icon: <AccountTree />,
        },
      ],
    },
    {
      text: "GIS",
      icon: <LocationOn />,
      subItems: [
        {
          text: "Maps",
          path: "/gis/maps",
          icon: <LocationOn />,
        },
        {
          text: "New Map",
          path: "/gis/new",
          icon: <Assignment />,
        },
      ],
    },
    {
      text: "Utility Data",
      icon: <DataUsage />,
      subItems: [
        {
          text: "Agrovets",
          path: "/utility/agrovets",
          icon: <Store />,
        },
        {
          text: "Care Clubs",
          path: "/utility/care-clubs",
          icon: <Groups />,
        },
        {
          text: "Community Groups",
          path: "/utility/community-groups",
          icon: <People />,
        },
        {
          text: "Equine Owners",
          path: "/utility/equine-owners",
          icon: <Pets />,
        },
        {
          text: "Farriers",
          path: "/utility/farriers",
          icon: <Build />,
        },
        {
          text: "Practitioners",
          path: "/utility/practitioners",
          icon: <LocalHospital />,
        },
      ],
    },
    { text: "Partners", icon: <Business />, path: "/partners" },
  ];

  useEffect(() => {
    if (user) {
      setMenuItems(adminItems);
    }
  }, [user]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Header
            setUser={setUser}
            handleDrawerOpen={handleDrawerOpen}
            open={open}
          />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box></Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <Fragment key={item.text}>
              {item.subItems ? (
                <>
                  <ListItem button onClick={() => handleToggle(item.text)}>
                    <ListItemIcon>
                      <ListItemIcon
                        sx={{
                          minWidth: 40,
                          color:
                            location.pathname === item.path
                              ? theme.palette.primary.main
                              : "inherit",
                          "& .MuiSvgIcon-root": {
                            fontSize: "1.3rem",
                            transition: "all 0.2s ease",
                          },
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        fontSize: "small",
                        color:
                          location.pathname === item.path
                            ? "primary"
                            : "textSecondary",
                        fontWeight:
                          location.pathname === item.path ? "bold" : "normal",
                      }}
                      primary={item.text}
                    />
                    {openSections[item.text] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {openSections[item.text] && (
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem) => (
                        <ListItem
                          key={subItem.text}
                          button
                          onClick={() => navigate(subItem.path)}
                          selected={location.pathname === subItem.path}
                          sx={{
                            fontSize: "x-small",
                            pl: 4,
                            typography: "body2",
                            fontStyle: "italic",
                            bgcolor:
                              location.pathname === subItem.path
                                ? "action.selected"
                                : "transparent",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 40,
                              color:
                                location.pathname === item.path
                                  ? theme.palette.primary.main
                                  : "inherit",
                              "& .MuiSvgIcon-root": {
                                fontSize: "1.3rem",
                                transition: "all 0.2s ease",
                              },
                            }}
                          >
                            {subItem.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={subItem.text}
                            sx={{
                              fontSize: "x-small",
                              color:
                                location.pathname === item.path
                                  ? "primary"
                                  : "textSecondary",
                              fontWeight:
                                location.pathname === item.path
                                  ? "bold"
                                  : "normal",
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </>
              ) : (
                <ListItem
                  key={item.text}
                  button
                  onClick={() => navigate(item.path)}
                  selected={location.pathname === item.path}
                  sx={{
                    cursor: "pointer",
                    bgcolor:
                      location.pathname === item.path
                        ? "action.selected"
                        : "transparent",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 40,
                      color:
                        location.pathname === item.path
                          ? theme.palette.primary.main
                          : "inherit",
                      "& .MuiSvgIcon-root": {
                        fontSize: "1.3rem",
                        transition: "all 0.2s ease",
                      },
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      cursor: "pointer",
                      color:
                        location.pathname === item.path
                          ? "primary"
                          : "textSecondary",
                      fontWeight:
                        location.pathname === item.path ? "bold" : "normal",
                    }}
                  />
                </ListItem>
              )}
            </Fragment>
          ))}
        </List>
        <Divider />
        <List>
          {user && user.Role === "Admin" && (
            <ListItem
              button
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/users")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 40,
                  "& .MuiSvgIcon-root": {
                    fontSize: "1.3rem",
                    transition: "all 0.2s ease",
                  },
                }}
              >
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          )}

          <ListItem
            button
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/profile")}
          >
            <ListItemIcon
              sx={{
                minWidth: 40,
                "& .MuiSvgIcon-root": {
                  fontSize: "1.3rem",
                  transition: "all 0.2s ease",
                },
              }}
            >
              <ProfileIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>

          <ListItem button onClick={logout} sx={{ cursor: "pointer" }}>
            <ListItemIcon
              sx={{
                minWidth: 40,
                "& .MuiSvgIcon-root": {
                  fontSize: "1.3rem",
                  transition: "all 0.2s ease",
                },
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Navbar;
