import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Stack,
  Avatar,
  Divider,
  Alert,
  Snackbar,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import {
  Person as PersonIcon,
  Edit as EditIcon,
  Lock as LockIcon,
} from "@mui/icons-material";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Partner: "",
    Level: "",
    Role: "",
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      // Get user ID from stored auth data
      const userID = JSON.parse(localStorage.getItem("user"))?.UserID;
      if (!userID) throw new Error("User not found");

      const response = await fetch(`/api/auth/${userID}`);
      const data = await response.json();
      setUser(data);
      setFormData({
        Name: data.Name,
        Email: data.Email,
        Phone: data.Phone,
        Partner: data.Partner,
        Level: data.Level,
        Role: data.Role,
      });
    } catch (error) {
      console.error("Error fetching profile:", error);
      showSnackbar("Failed to load profile", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const response = await fetch(`/api/auth/${user.UserID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        showSnackbar("Profile updated successfully");
        setEditMode(false);
        fetchUserProfile();
      } else {
        throw new Error("Update failed");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      showSnackbar("Failed to update profile", "error");
    }
  };

  const handleChangePassword = async () => {
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      showSnackbar("Passwords do not match", "error");
      return;
    }

    try {
      const response = await fetch(`/api/auth/${user.UserID}/password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currentPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword,
        }),
      });

      if (response.ok) {
        showSnackbar("Password updated successfully");
        setOpenPasswordDialog(false);
        setPasswordData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        throw new Error("Password update failed");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      showSnackbar("Failed to change password", "error");
    }
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  if (loading) {
    return (
      <Box sx={{ p: 3, display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: "0 auto" }}>
      <Card elevation={0} sx={{ borderRadius: 3, overflow: "hidden" }}>
        {/* Profile Header */}
        <Box
          sx={{
            bgcolor: "primary.main",
            color: "white",
            p: 3,
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "30%",
              height: "100%",
              background:
                "linear-gradient(45deg, transparent, rgba(255,255,255,0.1))",
            }}
          />
          <Stack direction="row" spacing={3} alignItems="center">
            <Avatar
              sx={{
                width: 80,
                height: 80,
                bgcolor: "rgba(255,255,255,0.2)",
                fontSize: "2rem",
              }}
            >
              {user?.Name?.charAt(0)}
            </Avatar>
            <Box>
              <Typography variant="h4" fontWeight="bold">
                {user?.Name}
              </Typography>
              <Typography variant="subtitle1" sx={{ opacity: 0.8 }}>
                {user?.Role} • {user?.Partner}
              </Typography>
            </Box>
          </Stack>
        </Box>

        <CardContent sx={{ p: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Stack spacing={3}>
                <Box>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Personal Information
                    {!editMode && (
                      <Button
                        startIcon={<EditIcon />}
                        onClick={() => setEditMode(true)}
                        sx={{ ml: 2 }}
                      >
                        Edit
                      </Button>
                    )}
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Name"
                        fullWidth
                        value={formData.Name}
                        onChange={(e) =>
                          setFormData({ ...formData, Name: e.target.value })
                        }
                        disabled={!editMode}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Email"
                        fullWidth
                        value={formData.Email}
                        onChange={(e) =>
                          setFormData({ ...formData, Email: e.target.value })
                        }
                        disabled={!editMode}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Phone"
                        fullWidth
                        value={formData.Phone}
                        onChange={(e) =>
                          setFormData({ ...formData, Phone: e.target.value })
                        }
                        disabled={!editMode}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {editMode && (
                  <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Button onClick={() => setEditMode(false)}>Cancel</Button>
                    <Button variant="contained" onClick={handleUpdateProfile}>
                      Save Changes
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card
                variant="outlined"
                sx={{ p: 3, borderRadius: 2, bgcolor: "grey.50" }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Account Settings
                </Typography>
                <Stack spacing={2}>
                  <Button
                    startIcon={<LockIcon />}
                    variant="outlined"
                    onClick={() => setOpenPasswordDialog(true)}
                    fullWidth
                  >
                    Change Password
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Password Change Dialog */}
      <Dialog
        open={openPasswordDialog}
        onClose={() => setOpenPasswordDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <TextField
              label="Current Password"
              type="password"
              value={passwordData.currentPassword}
              onChange={(e) =>
                setPasswordData({
                  ...passwordData,
                  currentPassword: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              label="New Password"
              type="password"
              value={passwordData.newPassword}
              onChange={(e) =>
                setPasswordData({
                  ...passwordData,
                  newPassword: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              label="Confirm New Password"
              type="password"
              value={passwordData.confirmPassword}
              onChange={(e) =>
                setPasswordData({
                  ...passwordData,
                  confirmPassword: e.target.value,
                })
              }
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPasswordDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleChangePassword}>
            Update Password
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Profile;
