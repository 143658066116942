import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import { createMarker } from "../../utils/mapConfig";

const LocationMap = ({ location, height = "400px" }) => {
  const mapRef = useRef();
  const mapInstance = useRef(null);

  useEffect(() => {
    if (!location) return;

    const coordinates = fromLonLat([
      parseFloat(location.Longitude),
      parseFloat(location.Latitude),
    ]);

    // Create vector source and layer for the marker
    const vectorSource = new VectorSource({
      features: [createMarker([location.Longitude, location.Latitude])],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    // Initialize map
    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: coordinates,
        zoom: 15,
      }),
    });

    mapInstance.current = map;

    // Cleanup
    return () => {
      if (mapInstance.current) {
        mapInstance.current.setTarget(null);
        mapInstance.current = null;
      }
    };
  }, [location]);

  return (
    <Box
      ref={mapRef}
      sx={{
        width: "100%",
        height,
        "& .ol-map": {
          width: "100%",
          height: "100%",
        },
      }}
    />
  );
};

export default LocationMap;
