import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const Results = ({ partner, start, end, title = "AMF" }) => {
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch data from API
  const fetchData = async () => {
    setLoading(true);
    setError(null);

    if (!partner || !start || !end) {
      setError("Missing required parameters");
      setLoading(false);
      return;
    }

    try {
      let url;
      switch (title) {
        case "AMF":
          url = `/api/data/agrovets/results/data/${encodeURIComponent(
            partner
          )}/${encodeURIComponent(start)}/${encodeURIComponent(end)}`;
          break;
        case "AHMF":
          url = `/api/data/practitioners/results/data/${encodeURIComponent(
            partner
          )}/${encodeURIComponent(start)}/${encodeURIComponent(end)}`;
          break;
        case "FMF":
          url = `/api/data/farriers/results/data/${encodeURIComponent(
            partner
          )}/${encodeURIComponent(start)}/${encodeURIComponent(end)}`;
          break;
        default:
          throw new Error("Invalid framework type");
      }

      console.log("Fetching from:", url, { partner, start, end });

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Raw data received:", result);

      if (Array.isArray(result?.data)) {
        console.log("First row sample:", result.data[0]);
        setData(result.data);
      } else {
        console.error("Invalid data format:", result);
        setError("Data format is incorrect");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(`Failed to fetch data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (partner && start && end) {
      console.log("Effect triggered with:", { partner, start, end, title });
      fetchData();
    }
  }, [partner, start, end, title]);

  // Column definitions based on framework type
  const getColumns = () => {
    // Base columns that are common to all frameworks
    const baseColumns = [
      {
        field: "Name",
        headerName: "Name",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "Date",
        headerName: "Date",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "Quarter",
        headerName: "Quarter",
        flex: 1,
        minWidth: 120,
      },
    ];

    // Framework specific columns
    const frameworkColumns = {
      AMF: [
        {
          field: "Location",
          headerName: "Location",
          flex: 1,
          minWidth: 150,
        },
        {
          field: "Score",
          headerName: "Score",
          flex: 1,
          minWidth: 110,
          type: "number",
        },
        {
          field: "Percentage",
          headerName: "Percentage",
          flex: 1,
          minWidth: 110,
        },
      ],
      FMF: [
        {
          field: "ALC_Site",
          headerName: "ALC Site",
          flex: 1,
          minWidth: 150,
        },
        {
          field: "Score",
          headerName: "Score",
          flex: 1,
          minWidth: 110,
        },
        {
          field: "Percentage",
          headerName: "Percentage",
          flex: 1,
          minWidth: 110,
        },
      ],
      AHMF: [
        {
          field: "Case_Summary",
          headerName: "Case Summary",
          flex: 1,
          minWidth: 150,
        },
        {
          field: "Score",
          headerName: "Score",
          flex: 1,
          minWidth: 110,
        },
        {
          field: "Percentage",
          headerName: "Percentage",
          flex: 1,
          minWidth: 110,
        },
      ],
    };

    // Return base columns + framework specific columns
    return [...baseColumns, ...(frameworkColumns[title] || [])];
  };

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          label="Filter by Name"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ width: 250 }}
        />
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box sx={{ p: 3, color: "error.main" }}>
          <Typography>{error}</Typography>
        </Box>
      ) : data.length === 0 ? (
        <Box sx={{ p: 3 }}>
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <DataGrid
          rows={data.filter((row) => {
            const searchName = (
              row.Name ||
              row.FarrierName ||
              row.AHP_Name ||
              row.Agrovet_Name ||
              ""
            ).toLowerCase();
            return searchName.includes(filter.toLowerCase());
          })}
          columns={getColumns()}
          pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          getRowId={(row) => row.ID}
          sx={{
            "& .MuiDataGrid-cell": {
              fontSize: "0.875rem",
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              fontWeight: 600,
            },
            border: 1,
            borderColor: "divider",
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
        />
      )}
    </Box>
  );
};

export default Results;
