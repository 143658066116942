import { createTheme } from "@mui/material/styles";

// Brooke Brand Colors
const colors = {
  primary: {
    main: "#F7941E", // Brooke Orange
    light: "#ff9f3d",
    dark: "#d67400",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#425563", // Brooke Grey
    light: "#6d7f8c",
    dark: "#2d3b45",
    contrastText: "#ffffff",
  },
  // Additional brand colors
  brooke: {
    blue: "#00A3E0",
    purple: "#662D91",
    green: "#8DC63F",
    yellow: "#FDB913",
    grey: "#425563",
    lightGrey: "#F5F5F5",
  },
  text: {
    primary: "#425563",
    secondary: "#6d7f8c",
  },
  background: {
    default: "#ffffff",
    paper: "#ffffff",
  },
};

const theme = createTheme({
  palette: {
    ...colors,
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    h1: {
      fontWeight: 700,
      color: colors.secondary.main,
    },
    h2: {
      fontWeight: 700,
      color: colors.secondary.main,
    },
    h3: {
      fontWeight: 600,
      color: colors.secondary.main,
    },
    h4: {
      fontWeight: 600,
      color: colors.secondary.main,
    },
    h5: {
      fontWeight: 600,
      color: colors.secondary.main,
    },
    h6: {
      fontWeight: 600,
      color: colors.secondary.main,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          textTransform: "none",
          fontWeight: 600,
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: colors.primary.dark,
          },
        },
        outlined: {
          borderColor: colors.primary.main,
          color: colors.primary.main,
          "&:hover": {
            borderColor: colors.primary.dark,
            backgroundColor: "rgba(247, 148, 30, 0.08)",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primary.main,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: `1px solid ${colors.brooke.lightGrey}`,
        },
      },
    },
  },
});

export default theme;
