import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { saveAs } from "file-saver";
import XLSX from "xlsx/dist/xlsx.full.min.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderRight: "1px solid rgba(224, 224, 224, 1)",
  "&:last-child": {
    borderRight: "none",
  },
  "&.header": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
    borderRight: "1px solid rgba(255, 255, 255, 0.2)",
  },
  "&.subheader": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderRight: "1px solid rgba(255, 255, 255, 0.2)",
  },
  "&.goal": {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
  },
  "&.so": {
    backgroundColor: "#fafafa",
    paddingLeft: theme.spacing(4),
  },
  "&.io": {
    paddingLeft: theme.spacing(6),
  },
  "&.output": {
    paddingLeft: theme.spacing(8),
  },
  "&.indicator": {
    paddingLeft: theme.spacing(10),
  },
  "&.target": {
    borderLeft: "2px solid rgba(224, 224, 224, 1)",
  },
  "&.achievement": {
    borderLeft: "2px solid rgba(224, 224, 224, 1)",
  },
  "&.variance": {
    borderLeft: "2px solid rgba(224, 224, 224, 1)",
  },
}));

const loadingMessages = [
  "Fetching goals...",
  "Loading strategic outcomes...",
  "Gathering intermediate outcomes...",
  "Collecting outputs...",
  "Compiling indicators...",
  "Calculating performance data...",
  "Almost there...",
  "Finalizing your IPTT...",
];

const WaveLoading = () => (
  <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
    <CircularProgress />
  </Box>
);

const LoadingMessage = () => {
  const [dots, setDots] = useState("");
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    // Dots animation
    const dotsInterval = setInterval(() => {
      setDots((prev) => (prev.length >= 3 ? "" : prev + "."));
    }, 500);

    // Message rotation
    const messageInterval = setInterval(() => {
      setMessageIndex((prev) => (prev + 1) % loadingMessages.length);
    }, 2000);

    return () => {
      clearInterval(dotsInterval);
      clearInterval(messageInterval);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
        gap: 2,
        minHeight: "200px",
        backgroundColor: "rgba(244, 81, 30, 0.02)",
        borderRadius: 2,
      }}
    >
      <Typography
        variant="h5"
        color="primary"
        sx={{
          fontWeight: 500,
          textAlign: "center",
          animation: "fadeIn 0.5s ease-in",
        }}
      >
        {loadingMessages[messageIndex]}
        {dots}
      </Typography>

      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          gap: 3,
        }}
      >
        <CircularProgress
          size={30}
          sx={{
            color: "#f4511e",
            animation: "pulse 1.5s ease-in-out infinite",
          }}
        />
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontStyle: "italic",
            animation: "fadeIn 0.5s ease-in",
          }}
        >
          This may take a few moments
        </Typography>
      </Box>

      <Typography
        variant="caption"
        color="text.secondary"
        sx={{
          mt: 2,
          maxWidth: "400px",
          textAlign: "center",
          animation: "fadeIn 0.5s ease-in",
        }}
      >
        We're compiling comprehensive data from all levels of your
        organization's hierarchy
      </Typography>
    </Box>
  );
};

const IPTT = () => {
  const [goals, setGoals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [partnerName, setPartnerName] = useState(null);

  useEffect(() => {
    const today = new Date();
    const currentMonth = today.getMonth(); // 0-11 where 0 is January
    const currentYear = today.getFullYear();

    // If we're before April (month < 3), we're in the previous financial year
    // If we're in or after April (month >= 3), we're in the current financial year
    const financialYear = currentMonth < 3 ? currentYear - 1 : currentYear;

    setYear(financialYear);
    setStart(`${financialYear}-04-01`);
    setEnd(`${financialYear + 1}-03-31`);
  }, []);

  useEffect(() => {
    if (start && end) {
      fetchIPTTData();
    }
  }, [start, end]);

  // Add useEffect to get partner name from localStorage
  useEffect(() => {
    try {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        try {
          const userData = JSON.parse(storedUser);
          const partner = userData?.Partner || userData?.partner;

          if (partner) {
            setPartnerName(partner);
          } else {
            console.error("No partner found in user data:", userData);
          }
        } catch (error) {
          console.error("Error parsing user data:", error);
        }
      } else {
        console.log("No user data found in localStorage");
      }
    } catch (error) {
      console.error("Error handling user data:", error);
    }
  }, []);

  const fetchIPTTData = async () => {
    if (!partnerName) {
      console.error("No partner found in user data");
      return;
    }

    setIsLoading(true);
    try {
      // 1. Fetch Goals
      const goalsResponse = await fetch("/api/goals");
      const goalsData = await goalsResponse.json();
      console.log("Goals fetched:", goalsData);

      // 2. Enrich each goal with its full hierarchy
      const enrichedGoals = await Promise.all(
        goalsData.data.map(async (goal) => {
          // Fetch Strategic Outcomes for each goal
          const sosResponse = await fetch(`/api/so/goal/${goal.ID}`);
          const sosData = await sosResponse.json();
          console.log(`SOs for goal ${goal.ID}:`, sosData);

          // Enrich each Strategic Outcome with its IOs
          const enrichedSOs = await Promise.all(
            sosData.data.map(async (so) => {
              // Get IOs for this SO
              const iosResponse = await fetch(`/api/io/so/${so.ID}`);
              const iosData = await iosResponse.json();
              console.log(`IOs for SO ${so.ID}:`, iosData);

              // Enrich each IO with its Outputs
              const enrichedIOs = await Promise.all(
                iosData.data.map(async (io) => {
                  try {
                    // Get outputs for this IO using partner from localStorage
                    const outputsResponse = await fetch(
                      `/api/outputs/io/${encodeURIComponent(partnerName)}/${
                        io.ID
                      }`
                    );
                    const outputsData = await outputsResponse.json();
                    console.log(`Outputs for IO ${io.ID}:`, outputsData);

                    if (!outputsData.data || outputsData.data.length === 0) {
                      return { ...io, outputs: [] };
                    }

                    // For each output, get its indicators
                    const indicatorsPromises = outputsData.data.map((output) =>
                      fetchIndicators(output.ID)
                    );
                    const indicatorsResults = await Promise.all(
                      indicatorsPromises
                    );

                    // Debug logs
                    console.log("All indicators results:", indicatorsResults);

                    const enrichedOutputs = await Promise.all(
                      outputsData.data.map(async (output) => {
                        try {
                          // Get indicators for this output
                          const indicatorsResponse = await fetch(
                            `/api/indicators/output/${output.ID}/0`
                          );
                          const indicatorsData =
                            await indicatorsResponse.json();
                          console.log(
                            `Indicators for output ${output.ID}:`,
                            indicatorsData
                          );

                          if (
                            !indicatorsData.data ||
                            indicatorsData.data.length === 0
                          ) {
                            return { ...output, indicators: [] };
                          }

                          // Get performance data for each indicator
                          const enrichedIndicators = await Promise.all(
                            indicatorsData.data.map(async (indicator) => {
                              try {
                                // Get performance data for this indicator
                                const performanceResponse = await fetch(
                                  `/api/performance/single/${indicator.ID}/${start}/${end}`
                                );
                                const performanceData =
                                  await performanceResponse.json();
                                console.log(
                                  `Performance for indicator ${indicator.ID}:`,
                                  performanceData
                                );

                                return {
                                  ...indicator,
                                  Baseline: indicator.Baseline || "0",
                                  Target_Annual: indicator.Target_Annual || "0",
                                  Target_Q1: indicator.Target_Q1 || "0",
                                  Target_Q2: indicator.Target_Q2 || "0",
                                  Target_Q3: indicator.Target_Q3 || "0",
                                  Target_Q4: indicator.Target_Q4 || "0",
                                  Achievement_Q1: performanceData?.Q1 || "0",
                                  Achievement_Q2: performanceData?.Q2 || "0",
                                  Achievement_Q3: performanceData?.Q3 || "0",
                                  Achievement_Q4: performanceData?.Q4 || "0",
                                  Annual_Achievement:
                                    performanceData?.Annual || "0",
                                  VarianceExplanation:
                                    performanceData?.Explanation || "",
                                };
                              } catch (error) {
                                console.error(
                                  `Error fetching performance for indicator ${indicator.ID}:`,
                                  error
                                );
                                return null;
                              }
                            })
                          );

                          // Filter out any failed indicator fetches
                          const validIndicators =
                            enrichedIndicators.filter(Boolean);

                          return {
                            ...output,
                            indicators: validIndicators,
                          };
                        } catch (error) {
                          console.error(
                            `Error fetching indicators for output ${output.ID}:`,
                            error
                          );
                          return { ...output, indicators: [] };
                        }
                      })
                    );

                    return {
                      ...io,
                      outputs: enrichedOutputs,
                    };
                  } catch (error) {
                    console.error(
                      `Error fetching outputs for IO ${io.ID}:`,
                      error
                    );
                    return { ...io, outputs: [] };
                  }
                })
              );

              return {
                ...so,
                ios: enrichedIOs,
              };
            })
          );

          return {
            ...goal,
            sos: enrichedSOs,
          };
        })
      );

      console.log("Final enriched data:", enrichedGoals);
      setGoals(enrichedGoals);
    } catch (error) {
      console.error("Error fetching IPTT data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Add effect to monitor goals state changes
  useEffect(() => {
    console.log("Goals state updated:", goals);
  }, [goals]);

  const renderHierarchy = (data) => {
    return data?.map((goal, index) => (
      <React.Fragment key={index}>
        <TableRow>
          <TableCell colSpan={15} sx={{ fontWeight: "bold" }}>
            {goal.Name}
          </TableCell>
        </TableRow>
        {goal.sos?.map((so, soIndex) => (
          <React.Fragment key={`${index}-${soIndex}`}>
            <TableRow>
              <TableCell colSpan={15} sx={{ pl: 4, fontWeight: "bold" }}>
                {so.Name}
              </TableCell>
            </TableRow>
            {so.ios?.map((io, ioIndex) => (
              <React.Fragment key={`${index}-${soIndex}-${ioIndex}`}>
                <TableRow>
                  <TableCell colSpan={15} sx={{ pl: 6, fontWeight: "bold" }}>
                    {io.Name}
                  </TableCell>
                </TableRow>
                {io.outputs?.map((output, outputIndex) => (
                  <RowItem
                    key={`${index}-${soIndex}-${ioIndex}-${outputIndex}`}
                    item={output}
                  />
                ))}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </React.Fragment>
    ));
  };

  const RowItem = ({ item }) => {
    return (
      <>
        {item?.indicators?.map((indicator, index) => (
          <TableRow key={index}>
            {index === 0 && (
              <TableCell rowSpan={item.indicators.length}>
                {item?.Description}
              </TableCell>
            )}
            <TableCell>{indicator?.Description}</TableCell>
            <TableCell align="center">{indicator?.Baseline}</TableCell>
            <TableCell align="center">{indicator?.Target_Annual}</TableCell>
            <TableCell align="center">{indicator?.Target_Q1}</TableCell>
            <TableCell align="center">{indicator?.Target_Q2}</TableCell>
            <TableCell align="center">{indicator?.Target_Q3}</TableCell>
            <TableCell align="center">{indicator?.Target_Q4}</TableCell>
            <TableCell align="center">{indicator?.Achievement_Q1}</TableCell>
            <TableCell align="center">{indicator?.Achievement_Q2}</TableCell>
            <TableCell align="center">{indicator?.Achievement_Q3}</TableCell>
            <TableCell align="center">{indicator?.Achievement_Q4}</TableCell>
            <TableCell align="center">
              {indicator?.Annual_Achievement}
            </TableCell>
            <TableCell>{indicator?.VarianceExplanation}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const getFinancialYearRange = (y) => `${y}/${y + 1}`;

  const downloadIPTT = () => {
    try {
      // Create worksheet data
      const wsData = [];

      // Add headers
      wsData.push([
        "Level",
        "Description",
        "Baseline",
        "Annual Target",
        "Q1 Target",
        "Q2 Target",
        "Q3 Target",
        "Q4 Target",
        "Q1 Achievement",
        "Q2 Achievement",
        "Q3 Achievement",
        "Q4 Achievement",
        "Annual Achievement",
        "Variance Explanation",
      ]);

      // Add data rows
      goals?.forEach((goal) => {
        // Add goal row
        wsData.push([
          "Goal",
          `${goal.Code}: ${goal.Name}`,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);

        goal.sos?.forEach((so) => {
          // Add strategic outcome row
          wsData.push([
            "Strategic Outcome",
            so.Name,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ]);

          so.ios?.forEach((io) => {
            // Add intermediate outcome row
            wsData.push([
              "Intermediate Outcome",
              io.Name,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ]);

            io.outputs?.forEach((output) => {
              // Add output row
              wsData.push([
                "Output",
                output.Description,
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
              ]);

              // Add indicator rows
              output.indicators?.forEach((indicator) => {
                wsData.push([
                  "Indicator",
                  indicator.Description,
                  indicator.Baseline,
                  indicator.Target_Annual,
                  indicator.Target_Q1,
                  indicator.Target_Q2,
                  indicator.Target_Q3,
                  indicator.Target_Q4,
                  indicator.Achievement_Q1,
                  indicator.Achievement_Q2,
                  indicator.Achievement_Q3,
                  indicator.Achievement_Q4,
                  indicator.Annual_Achievement,
                  indicator.VarianceExplanation,
                ]);
              });
            });
          });
        });
      });

      // Create workbook
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // Set column widths
      const colWidths = [
        { wch: 20 }, // Level
        { wch: 50 }, // Description
        { wch: 10 }, // Baseline
        { wch: 15 }, // Annual Target
        { wch: 10 }, // Q1 Target
        { wch: 10 }, // Q2 Target
        { wch: 10 }, // Q3 Target
        { wch: 10 }, // Q4 Target
        { wch: 10 }, // Q1 Achievement
        { wch: 10 }, // Q2 Achievement
        { wch: 10 }, // Q3 Achievement
        { wch: 10 }, // Q4 Achievement
        { wch: 15 }, // Annual Achievement
        { wch: 30 }, // Variance Explanation
      ];
      ws["!cols"] = colWidths;

      // Add worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, "IPTT");

      // Generate buffer
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      // Create blob and save file
      const blob = new Blob([wbout], { type: "application/octet-stream" });
      const filename = `IPTT_${getFinancialYearRange(year)}.xlsx`;
      saveAs(blob, filename);
    } catch (error) {
      console.error("Error downloading IPTT:", error);
    }
  };

  const keyframes = `
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      50% {
        transform: scale(1.1);
        opacity: 0.7;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
  `;

  const style = document.createElement("style");
  style.type = "text/css";
  style.innerHTML = keyframes;
  document.head.appendChild(style);

  const fetchIndicators = async (outputId) => {
    try {
      const response = await fetch(`/api/indicators/output/${outputId}/0`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(`Indicators for output ${outputId}:`, data); // Debug log
      return data.data || [];
    } catch (error) {
      console.error(`Error fetching indicators for output ${outputId}:`, error);
      return [];
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ color: "#f4511e", fontWeight: 500 }}
      >
        Indicator Performance Tracking Table
      </Typography>

      <Box
        sx={{
          mb: 3,
          display: "flex",
          gap: 2,
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          p: 2,
          borderRadius: 1,
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel>Financial Year</InputLabel>
            <Select
              value={year}
              onChange={(e) => {
                const selectedYear = e.target.value;
                setYear(selectedYear);
                setStart(`${selectedYear}-04-01`);
                setEnd(`${selectedYear + 1}-03-31`);
              }}
              label="Financial Year"
            >
              {Array.from({ length: 7 }, (_, i) => year - 3 + i).map((y) => (
                <MenuItem key={y} value={y}>
                  {getFinancialYearRange(y)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type="date"
            label="Start Date"
            value={start}
            InputLabelProps={{ shrink: true }}
            sx={{ bgcolor: "white" }}
            inputProps={{ readOnly: true }}
          />
          <TextField
            type="date"
            label="End Date"
            value={end}
            InputLabelProps={{ shrink: true }}
            sx={{ bgcolor: "white" }}
            inputProps={{ readOnly: true }}
          />
        </Box>

        {!isLoading && goals?.length > 0 && (
          <Button
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={downloadIPTT}
            sx={{
              backgroundColor: "#4caf50",
              "&:hover": {
                backgroundColor: "#388e3c",
              },
            }}
          >
            Download IPTT
          </Button>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table size="small" sx={{ minWidth: 1200 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell className="header" sx={{ width: "15%" }}>
                Indicator
              </StyledTableCell>
              <StyledTableCell className="header" sx={{ width: "20%" }}>
                Description
              </StyledTableCell>
              <StyledTableCell
                className="header"
                align="center"
                sx={{ width: "8%" }}
              >
                Baseline Value
              </StyledTableCell>
              <StyledTableCell
                className="header target"
                colSpan={5}
                align="center"
                sx={{ width: "25%" }}
              >
                Quarterly Targets
              </StyledTableCell>
              <StyledTableCell
                className="header achievement"
                colSpan={5}
                align="center"
                sx={{ width: "25%" }}
              >
                Achievements
              </StyledTableCell>
              <StyledTableCell
                className="header variance"
                sx={{ width: "15%" }}
              >
                Explanation of Variance
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                className="subheader"
                colSpan={2}
              ></StyledTableCell>
              <StyledTableCell className="subheader"></StyledTableCell>
              <StyledTableCell className="subheader target" align="center">
                Annual
              </StyledTableCell>
              <StyledTableCell className="subheader" align="center">
                Q1
              </StyledTableCell>
              <StyledTableCell className="subheader" align="center">
                Q2
              </StyledTableCell>
              <StyledTableCell className="subheader" align="center">
                Q3
              </StyledTableCell>
              <StyledTableCell className="subheader" align="center">
                Q4
              </StyledTableCell>
              <StyledTableCell className="subheader achievement" align="center">
                Q1
              </StyledTableCell>
              <StyledTableCell className="subheader" align="center">
                Q2
              </StyledTableCell>
              <StyledTableCell className="subheader" align="center">
                Q3
              </StyledTableCell>
              <StyledTableCell className="subheader" align="center">
                Q4
              </StyledTableCell>
              <StyledTableCell className="subheader" align="center">
                Annual
              </StyledTableCell>
              <StyledTableCell className="subheader variance"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={14}>
                  <LoadingMessage />
                </TableCell>
              </TableRow>
            ) : (
              goals?.map((goal) => (
                <React.Fragment key={goal.ID}>
                  {/* Global Goal Row */}
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell colSpan={14} sx={{ fontWeight: "bold" }}>
                      Global goal {goal.Number}: {goal.Name}
                    </TableCell>
                  </TableRow>

                  {goal.sos?.map((so) => (
                    <React.Fragment key={so.ID}>
                      {/* Strategic Outcome Row */}
                      <TableRow sx={{ backgroundColor: "#fafafa" }}>
                        <TableCell
                          colSpan={14}
                          sx={{ pl: 4, fontWeight: "bold" }}
                        >
                          Strategic Outcome {so.Number}: {so.Name}
                        </TableCell>
                      </TableRow>

                      {so.ios?.map((io) => (
                        <React.Fragment key={io.ID}>
                          {/* Intermediate Outcome Row */}
                          <TableRow>
                            <TableCell
                              colSpan={14}
                              sx={{ pl: 6, fontWeight: "bold" }}
                            >
                              Intermediate Outcome {io.Number}: {io.Name}
                            </TableCell>
                          </TableRow>

                          {io.outputs?.map((output) => (
                            <React.Fragment key={output.ID}>
                              {/* Output Row */}
                              <TableRow>
                                <TableCell colSpan={14} sx={{ pl: 8 }}>
                                  Output {output.Number}: {output.Description}
                                </TableCell>
                              </TableRow>

                              {/* Indicator Rows */}
                              {output.indicators?.map((indicator) => (
                                <TableRow key={indicator.ID}>
                                  <StyledTableCell className="indicator">
                                    Indicator {indicator.Number}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {indicator.Description}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {indicator.Baseline}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className="target"
                                    align="center"
                                  >
                                    {indicator.Target_Annual}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {indicator.Target_Q1}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {indicator.Target_Q2}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {indicator.Target_Q3}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {indicator.Target_Q4}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className="achievement"
                                    align="center"
                                  >
                                    {indicator.Achievement_Q1}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {indicator.Achievement_Q2}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {indicator.Achievement_Q3}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {indicator.Achievement_Q4}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {indicator.Annual_Achievement}
                                  </StyledTableCell>
                                  <StyledTableCell className="variance">
                                    {indicator.VarianceExplanation}
                                  </StyledTableCell>
                                </TableRow>
                              ))}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default IPTT;
