import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from "@mui/material";
import { TrendingUp, Search } from "@mui/icons-material";
import AgrovetTrendDialog from "./TrendDialogs/AgrovetTrendDialog";
import PractitionerTrendDialog from "./TrendDialogs/PractitionerTrendDialog";
import FarrierTrendDialog from "./TrendDialogs/FarrierTrendDialog";

const Competencies = ({ title, partner, start, end }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [trendDialogOpen, setTrendDialogOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchCompetencyData = async () => {
      if (!partner || !start || !end) return;

      setLoading(true);
      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 30000);

        const endpoint =
          title === "AHMF"
            ? `/api/data/practitioners/competency/data/${partner}/${start}/${end}`
            : title === "FMF"
            ? `/api/data/farriers/competency/data/${partner}/${start}/${end}`
            : `/api/data/agrovets/competency/data/${partner}/${start}/${end}`;

        console.log("Fetching from endpoint:", endpoint);

        const response = await fetch(endpoint, {
          signal: controller.signal,
        });

        clearTimeout(timeoutId);

        if (!response.ok) {
          console.error("Response status:", response.status);
          console.error("Response text:", await response.text());
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Raw API Response:", result);

        if (result && result.data) {
          const transformedData = result.data.map((item) => {
            if (title === "AMF") {
              return {
                id: item.ID,
                ID: item.ID,
                Name: item.Agrovet_Name,
                Quarter: item.Reporting_Quarter,
                Date: item.Assessment_Date,
                "Legal Compliance": item["Legal Compliance"],
                Communicator: item.Communicator,
                "Pharmacological Expert": item["Pharmacological Expert"],
                "Content of Agrovet": item["Content of Agrovet"],
                "Agrovet Governance": item["Agrovet Governance"],
              };
            }

            if (title === "FMF") {
              return {
                id: item.ID,
                ID: item.ID,
                Name: item.Farrier_Name,
                Quarter: item.Quarter,
                ALC_Site: item.ALC_Site,
                Date: item.Date,
                "Legal Compliance": item["Legal Compliance"],
                Communicator: item.Communicator,
                "Farriery Expert": item["Farriery Expert"],
                "Farrier Kit": item["Farrier Kit"],
                Governance: item.Governance,
              };
            }

            // AHMF transformation
            return {
              id: item.ID,
              ID: item.ID,
              Name: item.AHP_Name,
              Quarter: item.Quarter,
              Date: item.Date,
              "Legal Compliance": item.LegalComplicance,
              "Clinical Expert": item.ClinicalExpert,
              "Kit Content": item.Content,
              "Clinical Governance": item.ClinicalGovernance,
            };
          });

          console.log("Final transformed data:", transformedData);
          setData(transformedData);
          setFilteredData(transformedData);
        }
      } catch (error) {
        if (error.name === "AbortError") {
          console.error("Request timed out");
          setError("Request timed out. Please try again.");
        } else {
          console.error("Error fetching competency data:", error);
          console.error("Error details:", error.message);
        }
        setData([]);
        setFilteredData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCompetencyData();
  }, [partner, start, end, title]);

  // Update filtered data when data or search term changes
  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredData(data);
      return;
    }

    const filtered = data.filter((item) =>
      item.Name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [data, searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Framework-specific columns
  const getColumns = () => {
    // Common base columns without ALC_Site
    const commonColumns = [
      { field: "Name", headerName: "Name", flex: 1 },
      { field: "Quarter", headerName: "Quarter", width: 120 },
      { field: "Date", headerName: "Date", width: 120 },
      {
        field: "Legal Compliance",
        headerName: "Legal Compliance",
        width: 150,
      },
    ];

    // Add ALC_Site for AHMF and FMF only
    const baseColumns =
      title === "AMF" || title === "AHMF"
        ? commonColumns
        : [
            ...commonColumns.slice(0, 2), // Insert ALC_Site after Quarter
            { field: "ALC_Site", headerName: "ALC Site", width: 150 },
            ...commonColumns.slice(2), // Rest of the columns
          ];

    if (title === "AMF") {
      return [
        ...baseColumns,
        {
          field: "Communicator",
          headerName: "Communicator",
          width: 150,
        },
        {
          field: "Pharmacological Expert",
          headerName: "Pharmacological Expert",
          width: 150,
        },
        {
          field: "Content of Agrovet",
          headerName: "Content of Agrovet",
          width: 150,
        },
        {
          field: "Agrovet Governance",
          headerName: "Agrovet Governance",
          width: 150,
        },
        getTrendColumn(),
      ];
    } else if (title === "FMF") {
      return [
        ...baseColumns,
        {
          field: "Communicator",
          headerName: "Communicator",
          width: 150,
        },
        {
          field: "Farriery Expert",
          headerName: "Farriery Expert",
          width: 150,
        },
        {
          field: "Farrier Kit",
          headerName: "Farrier Kit",
          width: 150,
        },
        {
          field: "Governance",
          headerName: "Governance",
          width: 150,
        },
        getTrendColumn(),
      ];
    } else {
      return [
        ...baseColumns,
        {
          field: "Clinical Expert",
          headerName: "Clinical Expert",
          width: 150,
        },
        {
          field: "Kit Content",
          headerName: "Kit Content",
          width: 150,
        },
        {
          field: "Clinical Governance",
          headerName: "Clinical Governance",
          width: 150,
        },
        getTrendColumn(),
      ];
    }
  };

  const getTrendColumn = () => ({
    field: "actions",
    headerName: "Trend",
    width: 100,
    sortable: false,
    renderCell: (params) => {
      console.log("Row data for trend:", params.row);

      return (
        <Tooltip title="View Trend">
          <IconButton
            onClick={() => {
              setSelectedRecord(params.row);
              setTrendDialogOpen(true);
            }}
            size="small"
          >
            <TrendingUp />
          </IconButton>
        </Tooltip>
      );
    },
  });

  const getTrendDialog = () => {
    switch (title) {
      case "AMF":
        return (
          <AgrovetTrendDialog
            open={trendDialogOpen}
            onClose={() => setTrendDialogOpen(false)}
            record={selectedRecord}
            framework={title}
            year={new Date(start).getFullYear()}
          />
        );
      case "AHMF":
        return (
          <PractitionerTrendDialog
            open={trendDialogOpen}
            onClose={() => setTrendDialogOpen(false)}
            record={selectedRecord}
            framework={title}
            year={new Date(start).getFullYear()}
          />
        );
      case "FMF":
        return (
          <FarrierTrendDialog
            open={trendDialogOpen}
            onClose={() => setTrendDialogOpen(false)}
            record={selectedRecord}
            framework={title}
            year={new Date(start).getFullYear()}
          />
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 3,
          color: "error.main",
        }}
      >
        <Typography>Error loading data: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      {/* Search Box */}
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          size="small"
          placeholder={`Search ${
            title === "AMF" ? "agrovet" : title === "AHMF" ? "AHP" : "farrier"
          } name...`}
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {console.log("Rendering grid with data:", filteredData)}
      {console.log("Using columns:", getColumns())}
      <DataGrid
        rows={filteredData}
        columns={getColumns()}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        density="comfortable"
        loading={loading}
        getRowId={(row) => row.ID}
      />
      {getTrendDialog()}
    </Box>
  );
};

export default Competencies;
