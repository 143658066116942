import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import TrendTable from "./TrendTable";

const FarrierTrendDialog = ({ open, onClose, record, framework, year }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!record?.ID) return;

      try {
        setLoading(true);
        const response = await fetch(`/api/data/farriers/trend/${record.ID}`);
        const result = await response.json();
        setData(result.data);
      } catch (err) {
        console.error("Error fetching trend data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchData();
    }
  }, [open, record]);

  const metrics = [
    { key: "legal_compliance", label: "Legal Compliance" },
    { key: "communicator", label: "Communicator" },
    { key: "farriery_expert", label: "Farriery Expert" },
    { key: "farrier_kit", label: "Farrier Kit" },
    { key: "governance", label: "Governance" },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Performance History - {record?.Farrier_Name}
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : !data || data.length === 0 ? (
          <Typography color="textSecondary" sx={{ p: 3, textAlign: "center" }}>
            No performance history available
          </Typography>
        ) : (
          <TrendTable data={data} metrics={metrics} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FarrierTrendDialog;
