import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography, CircularProgress } from "@mui/material";
import {
  BarChart as BarChartIcon,
  TrendingUp,
  Assessment,
} from "@mui/icons-material";
import theme from "../../../theme";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";

const InsightCard = ({ children, icon, title }) => (
  <Paper
    sx={{
      p: 2,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      bgcolor: "background.paper",
      borderRadius: 2,
      boxShadow: 1,
    }}
  >
    <Box display="flex" alignItems="center" mb={2}>
      {icon}
      <Typography variant="h6" ml={1}>
        {title}
      </Typography>
    </Box>
    {children}
  </Paper>
);

const Top = ({ framework, partner, dateRange, onDataLoad }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  // Get the correct endpoint based on framework
  const getEndpoint = () => {
    switch (framework) {
      case "agrovets":
        return "/agrovets/monitoring/insights";
      case "farriers":
        return "/farriers/monitoring/insights";
      case "practitioners":
        return "/practitioners/monitoring/insights";
      default:
        throw new Error(`Unknown framework: ${framework}`);
    }
  };

  // Get the correct name field based on framework
  const getNameField = () => {
    switch (framework) {
      case "agrovets":
        return "Agrovet_Name";
      case "farriers":
        return "Farrier_Name";
      case "practitioners":
        return "AHP_Name";
      default:
        return "Name";
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      // Reset states when parameters change
      if (isMounted) {
        setLoading(true);
        setError(null);
        setData(null);
      }

      if (!partner || !dateRange?.start || !dateRange?.end) {
        if (isMounted) setLoading(false);
        return;
      }

      try {
        const endpoint = getEndpoint();
        console.log(`Fetching ${framework} data:`, {
          partner,
          start: dateRange.start,
          end: dateRange.end,
          endpoint,
        });

        const response = await fetch(
          `/api${endpoint}/${encodeURIComponent(partner)}/${dateRange.start}/${
            dateRange.end
          }`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log(`${framework} insights data received:`, result);

        if (!result.success) {
          throw new Error(result.error || "Failed to fetch data");
        }

        if (isMounted) {
          const nameField = getNameField();
          const processedData = {
            assessments:
              result.data?.assessments?.map((assessment) => ({
                ...assessment,
                Name: assessment[nameField] || "Unknown",
                Percentage: parseFloat(assessment.Percentage) || 0,
              })) || [],
            competencies:
              result.data?.competencies?.map((comp) => ({
                ...comp,
                category: comp.category || "",
                value: parseFloat(comp.value) || 0,
              })) || [],
            trends:
              result.data?.trends?.map((trend) => ({
                ...trend,
                month: trend.month || "",
                average_score: parseFloat(trend.average_score) || 0,
                count: parseInt(trend.count) || 0,
              })) || [],
          };

          setData(processedData);
          if (onDataLoad) {
            onDataLoad(processedData);
          }
        }
      } catch (error) {
        console.error(`Error fetching ${framework} insights:`, error);
        if (isMounted) {
          setError(error.message);
          // Clear data on error
          setData(null);
          if (onDataLoad) {
            onDataLoad(null);
          }
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [framework, partner, dateRange?.start, dateRange?.end, onDataLoad]);

  const renderPerformanceSummary = () => {
    if (!data?.assessments) return null;

    const totalAssessments = data.assessments.length;
    const uniqueNames = new Set(data.assessments.map((a) => a.Name)).size;

    const averageScore = totalAssessments
      ? (
          data.assessments.reduce(
            (acc, curr) => acc + (curr.Percentage || 0),
            0
          ) / totalAssessments
        ).toFixed(1)
      : 0;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" color="textSecondary">
            Total{" "}
            {framework === "agrovets"
              ? "Agrovets"
              : framework === "farriers"
              ? "Farriers"
              : "Practitioners"}
          </Typography>
          <Typography variant="h4">{uniqueNames}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" color="textSecondary">
            Total Assessments
          </Typography>
          <Typography variant="h4">{totalAssessments}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" color="textSecondary">
            Average Score
          </Typography>
          <Typography variant="h4">{`${averageScore}%`}</Typography>
        </Grid>
      </Grid>
    );
  };

  // Loading state with message
  if (loading) {
    return (
      <Box
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <CircularProgress />
        <Typography variant="body2" color="textSecondary">
          Loading {framework} data...
        </Typography>
      </Box>
    );
  }

  // Error state with details
  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" gutterBottom>
          Error loading data
        </Typography>
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  // No data state with framework-specific message
  if (!data || !data.assessments || !data.competencies || !data.trends) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="textSecondary">
          No {framework} monitoring data available for the selected period.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        {/* Competency Overview */}
        <Grid item xs={12} md={8}>
          <InsightCard
            icon={<Assessment sx={{ color: theme.palette.primary.main }} />}
            title={`${
              framework === "agrovets"
                ? "Agrovet"
                : framework === "farriers"
                ? "Farrier"
                : "Practitioner"
            } Competency Overview`}
          >
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data?.competencies || []}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="category"
                  angle={-45}
                  textAnchor="end"
                  height={80}
                  interval={0}
                />
                <YAxis
                  domain={[0, 100]}
                  tickFormatter={(value) => `${value}%`}
                />
                <Tooltip
                  formatter={(value) => [`${value}%`, "Success Rate"]}
                  cursor={{ fill: "transparent" }}
                />
                <Legend />
                <Bar
                  dataKey="value"
                  fill={theme.palette.primary.main}
                  name="Success Rate"
                />
              </BarChart>
            </ResponsiveContainer>
          </InsightCard>
        </Grid>

        {/* Monthly Trends */}
        <Grid item xs={12} md={4}>
          <InsightCard
            icon={<TrendingUp sx={{ color: theme.palette.primary.main }} />}
            title="Monthly Assessment Trends"
          >
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={data?.trends || []}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis
                  yAxisId="left"
                  domain={[0, 100]}
                  tickFormatter={(value) => `${value}%`}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  domain={[0, "auto"]}
                />
                <Tooltip
                  formatter={(value, name) => [
                    name === "Average Score" ? `${value}%` : value,
                    name,
                  ]}
                />
                <Legend />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="average_score"
                  stroke={theme.palette.primary.main}
                  name="Average Score"
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="count"
                  stroke={theme.palette.secondary.main}
                  name="Unique Assessments"
                />
              </LineChart>
            </ResponsiveContainer>
          </InsightCard>
        </Grid>

        {/* Summary Statistics */}
        <Grid item xs={12}>
          <InsightCard
            icon={<BarChartIcon sx={{ color: theme.palette.primary.main }} />}
            title="Performance Summary"
          >
            {renderPerformanceSummary()}
          </InsightCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Top;
