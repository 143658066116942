import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  Typography,
  Button,
  Container,
  Paper,
  IconButton,
  Divider,
} from "@mui/material";
import {
  Assessment,
  Pets,
  Groups,
  Store,
  School,
  Build,
  LocalHospital,
  ArrowForward,
  Dashboard,
  TrendingUp,
  BarChart,
} from "@mui/icons-material";
import theme from "../../theme";
import { alpha } from "@mui/material/styles";

const Home = ({ user }) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`/api/data/all/stats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setStats(data);
        setLoading(false);
      })
      .catch((e) => {
        console.error("Error fetching stats:", e);
        setLoading(false);
      });
  }, []);

  const navigateToPage = (path) => {
    window.location.href = path;
  };

  const StatBox = ({ title, value, icon, trend, onClick }) => (
    <Paper
      elevation={0}
      sx={{
        height: "100%",
        backgroundColor: "#fff",
        borderRadius: 2,
        cursor: onClick ? "pointer" : "default",
        transition: "all 0.3s ease",
        position: "relative",
        overflow: "hidden",
        border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        "&:hover": onClick
          ? {
              transform: "translateY(-4px)",
              boxShadow: `0 12px 24px -10px ${alpha(
                theme.palette.primary.main,
                0.2
              )}`,
              "& .stat-bg": {
                transform: "scale(1.1)",
              },
            }
          : {},
      }}
      onClick={onClick}
    >
      <Box
        className="stat-bg"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "150px",
          height: "150px",
          background: `radial-gradient(circle, ${alpha(
            theme.palette.primary.main,
            0.05
          )} 0%, transparent 70%)`,
          transition: "transform 0.3s ease",
          transform: "scale(1)",
          transformOrigin: "top right",
        }}
      />
      <Box sx={{ position: "relative", p: 3, zIndex: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Box
            sx={{
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              borderRadius: "12px",
              p: 1.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: theme.palette.primary.main,
              mr: 2,
            }}
          >
            {icon}
          </Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              color: theme.palette.secondary.main,
              flex: 1,
              fontSize: "0.95rem",
            }}
          >
            {title}
          </Typography>
          {trend && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: theme.palette.success.main,
              }}
            >
              <TrendingUp sx={{ fontSize: 20, mr: 0.5 }} />
              <Typography
                variant="body2"
                fontWeight={600}
                sx={{ fontSize: "0.75rem" }}
              >
                {trend}
              </Typography>
            </Box>
          )}
        </Box>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            color: theme.palette.primary.main,
            mb: 1,
            fontSize: "2rem",
          }}
        >
          {value?.toLocaleString() || "0"}
        </Typography>
        <Box
          sx={{
            width: "60px",
            height: "3px",
            background: `linear-gradient(to right, ${
              theme.palette.primary.main
            }, ${alpha(theme.palette.primary.main, 0.2)})`,
          }}
        />
      </Box>
    </Paper>
  );

  const ActionButton = ({ children, onClick, fullWidth = false }) => (
    <Button
      fullWidth={fullWidth}
      onClick={onClick}
      sx={{
        position: "relative",
        py: 1.5,
        px: 2.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: "#fff",
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 0,
        color: theme.palette.primary.main,
        overflow: "hidden",
        zIndex: 1,
        height: 48,
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "0%",
          height: "100%",
          backgroundColor: theme.palette.primary.main,
          transition: "width 0.3s ease",
          zIndex: -1,
        },
        "&:hover": {
          color: "#fff",
          borderColor: theme.palette.primary.main,
          "&::before": {
            width: "100%",
          },
        },
        "& .MuiSvgIcon-root": {
          transition: "all 0.3s ease",
          fontSize: 20,
        },
        "&:hover .MuiSvgIcon-root": {
          color: "#fff",
        },
      }}
    >
      {children}
    </Button>
  );

  const FrameworkCard = ({ title, description, icon, path, stats }) => (
    <Paper
      elevation={0}
      sx={{
        height: "100%",
        backgroundColor: "#fff",
        borderRadius: 2,
        transition: "all 0.3s ease",
        position: "relative",
        overflow: "hidden",
        border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        display: "flex",
        flexDirection: "column",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: `0 12px 24px -10px ${alpha(
            theme.palette.primary.main,
            0.2
          )}`,
          "& .framework-bg": {
            transform: "scale(1.1)",
          },
        },
      }}
    >
      <Box
        className="framework-bg"
        sx={{
          position: "absolute",
          top: -50,
          right: -50,
          width: "200px",
          height: "200px",
          background: `radial-gradient(circle, ${alpha(
            theme.palette.primary.main,
            0.05
          )} 0%, transparent 70%)`,
          transition: "transform 0.3s ease",
          transform: "scale(1)",
          transformOrigin: "top right",
        }}
      />
      <Box
        sx={{
          position: "relative",
          p: 3,
          zIndex: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box
            sx={{
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              borderRadius: "12px",
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: theme.palette.primary.main,
              mr: 2,
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                color: theme.palette.secondary.main,
                mb: 0.5,
                fontSize: "1.1rem",
              }}
            >
              {title}
            </Typography>
            {stats && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <BarChart
                  sx={{
                    fontSize: 18,
                    color: theme.palette.primary.main,
                    mr: 1,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.text.secondary,
                    fontSize: "0.8rem",
                  }}
                >
                  {stats}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.text.secondary,
            mb: 4,
            lineHeight: 1.6,
            flex: 1,
            fontSize: "0.9rem",
          }}
        >
          {description}
        </Typography>
        <Box>
          <ActionButton onClick={() => navigateToPage(path)}>
            <Typography
              variant="button"
              sx={{
                fontSize: "0.8rem",
                fontWeight: 600,
                mr: 1,
              }}
            >
              Access Framework
            </Typography>
            <ArrowForward sx={{ fontSize: 18 }} />
          </ActionButton>
        </Box>
      </Box>
    </Paper>
  );

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: alpha(theme.palette.primary.main, 0.02),
      }}
    >
      <Container maxWidth="xl" sx={{ py: 6 }}>
        {/* Welcome Section */}
        <Box sx={{ mb: 8 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: 700,
                color: theme.palette.secondary.main,
                position: "relative",
                fontSize: "1.75rem",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: -8,
                  left: 0,
                  width: 60,
                  height: 4,
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              Welcome back, {user?.Name}
            </Typography>
          </Box>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.text.secondary,
              maxWidth: 800,
              lineHeight: 1.6,
              mt: 3,
              fontSize: "0.95rem",
            }}
          >
            Access and manage GIS data, frameworks, and monitoring tools for
            Brooke East Africa's operations.
          </Typography>
        </Box>

        {/* Stats Section */}
        <Box sx={{ mb: 8 }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              color: theme.palette.secondary.main,
              mb: 4,
              fontSize: "1.25rem",
            }}
          >
            Overview
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <StatBox
                title="Practitioners"
                value={stats?.Practitioners}
                icon={<LocalHospital />}
                trend="+12%"
                onClick={() => navigateToPage("/utility/practitioners")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StatBox
                title="Agrovets"
                value={stats?.Agrovets}
                icon={<Store />}
                onClick={() => navigateToPage("/utility/agrovets")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StatBox
                title="Community Groups"
                value={stats?.Communitygroups}
                icon={<Groups />}
                onClick={() => navigateToPage("/utility/community-groups")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StatBox
                title="Care Clubs"
                value={stats?.Careclubs}
                icon={<School />}
                onClick={() => navigateToPage("/utility/care-clubs")}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Frameworks Section */}
        <Box sx={{ mb: 8 }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              color: theme.palette.secondary.main,
              mb: 4,
              fontSize: "1.25rem",
            }}
          >
            Key Frameworks
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <FrameworkCard
                title="Agrovets Monitoring"
                description="Build skills, address gaps, and strengthen inter-professional relationships within the agrovet sector."
                icon={<Assessment />}
                path="/frameworks/amf"
                stats="234 assessments this quarter"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FrameworkCard
                title="Farriers Monitoring"
                description="Raise the profile of farriery as a trade and improve hoof health for working equines."
                icon={<Build />}
                path="/frameworks/fmf"
                stats="156 assessments this quarter"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FrameworkCard
                title="Animal Health"
                description="Monitor and improve essential skills and competencies for quality animal health services."
                icon={<Pets />}
                path="/frameworks/ahmf"
                stats="189 assessments this quarter"
              />
            </Grid>
          </Grid>
        </Box>

        {/* Quick Actions Section */}
        <Box>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              color: theme.palette.secondary.main,
              mb: 4,
              fontSize: "1.25rem",
            }}
          >
            Quick Actions
          </Typography>
          <Grid container spacing={2}>
            {[
              { title: "View Maps", icon: <Dashboard />, path: "/gis/maps" },
              {
                title: "Equine Owners",
                icon: <Pets />,
                path: "/utility/equine-owners",
              },
              { title: "Farriers", icon: <Build />, path: "/utility/farriers" },
            ].map((action, index) => (
              <Grid item xs={12} md={4} key={index}>
                <ActionButton
                  onClick={() => navigateToPage(action.path)}
                  fullWidth
                >
                  <Box
                    sx={{
                      mr: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {action.icon}
                  </Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 500,
                      flex: 1,
                      fontSize: "0.875rem",
                    }}
                  >
                    {action.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ArrowForward />
                  </Box>
                </ActionButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Home;
