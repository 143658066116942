import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Tooltip,
  Divider,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Business as BusinessIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingPartner, setEditingPartner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [formData, setFormData] = useState({
    Name: "",
    Country: "",
    Offices: "",
    AreaOfDuty: "",
    Projects: "",
    Website: "",
    ContactPerson: "",
    Telephone: "",
    Email: "",
    Address: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const searchPartners = async (query) => {
    if (!query.trim()) {
      setIsSearching(false);
      fetchPartners();
      return;
    }

    setIsSearching(true);
    try {
      const response = await fetch(`/api/partners/quicksearch/${query}`);
      if (!response.ok) {
        throw new Error("Search failed");
      }
      const data = await response.json();
      setSearchResults(data.data || []);
    } catch (error) {
      console.error("Error searching partners:", error);
      showSnackbar("Error searching partners", "error");
    } finally {
      setIsSearching(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => searchPartners(query), 300),
    []
  );

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  useEffect(() => {
    fetchPartners();
  }, [page]);

  const fetchPartners = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/partners/all/${page * rowsPerPage}`);
      const data = await response.json();
      setPartners(data.data || []);
    } catch (error) {
      console.error("Error fetching partners:", error);
      showSnackbar("Failed to load partners", "error");
    } finally {
      setLoading(false);
    }
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenDialog = (partner = null) => {
    if (partner) {
      setEditingPartner(partner);
      setFormData(partner);
    } else {
      setEditingPartner(null);
      setFormData({
        Name: "",
        Country: "",
        Offices: "",
        AreaOfDuty: "",
        Projects: "",
        Website: "",
        ContactPerson: "",
        Telephone: "",
        Email: "",
        Address: "",
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingPartner(null);
  };

  const handleSubmit = async () => {
    try {
      const url = editingPartner
        ? `/api/partners/${editingPartner.ID}`
        : "/api/partners/create";
      const method = editingPartner ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        handleCloseDialog();
        fetchPartners();
        showSnackbar(
          editingPartner
            ? "Partner updated successfully"
            : "Partner created successfully"
        );
      } else {
        showSnackbar("Error saving partner", "error");
      }
    } catch (error) {
      console.error("Error saving partner:", error);
      showSnackbar("Error saving partner", "error");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this partner?")) {
      try {
        const response = await fetch(`/api/partners/${id}`, {
          method: "DELETE",
        });

        if (response.ok) {
          fetchPartners();
          showSnackbar("Partner deleted successfully");
        } else {
          showSnackbar("Error deleting partner", "error");
        }
      } catch (error) {
        console.error("Error deleting partner:", error);
        showSnackbar("Error deleting partner", "error");
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={0} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}>
          <BusinessIcon sx={{ fontSize: 40, color: "primary.main" }} />
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              Partners
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Manage your organization's partners and collaborators
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <TextField
            placeholder="Search partners..."
            value={searchQuery}
            onChange={handleSearch}
            size="small"
            sx={{
              minWidth: 300,
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
              endAdornment: isSearching && (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
            sx={{
              borderRadius: 2,
              textTransform: "none",
              px: 3,
            }}
          >
            Add Partner
          </Button>
        </Box>
      </Paper>

      <Paper sx={{ borderRadius: 2 }}>
        <TableContainer>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Country</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Contact Person</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Telephone</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(searchQuery ? searchResults : partners).map((partner) => (
                  <TableRow
                    key={partner.ID}
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{partner.Name}</TableCell>
                    <TableCell>{partner.Country}</TableCell>
                    <TableCell>{partner.ContactPerson}</TableCell>
                    <TableCell>{partner.Email}</TableCell>
                    <TableCell>{partner.Telephone}</TableCell>
                    <TableCell>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => handleOpenDialog(partner)}
                          size="small"
                          sx={{ color: "primary.main" }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => handleDelete(partner.ID)}
                          size="small"
                          sx={{ color: "error.main" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
                {(searchQuery ? searchResults : partners).length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
                      <Typography color="text.secondary">
                        {searchQuery
                          ? "No partners found matching your search"
                          : "No partners available"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
          <Divider />
          {!searchQuery && (
            <TablePagination
              component="div"
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[10]}
            />
          )}
        </TableContainer>
      </Paper>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: { borderRadius: 2 },
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            {editingPartner ? "Edit Partner" : "Add New Partner"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {editingPartner
              ? "Update the partner's information"
              : "Enter the details for the new partner"}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ py: 3 }}>
          <Box
            sx={{
              display: "grid",
              gap: 3,
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <TextField
              label="Name"
              value={formData.Name}
              onChange={(e) =>
                setFormData({ ...formData, Name: e.target.value })
              }
              fullWidth
              size="small"
            />
            <TextField
              label="Country"
              value={formData.Country}
              onChange={(e) =>
                setFormData({ ...formData, Country: e.target.value })
              }
              fullWidth
              size="small"
            />
            <TextField
              label="Offices"
              value={formData.Offices}
              onChange={(e) =>
                setFormData({ ...formData, Offices: e.target.value })
              }
              fullWidth
              size="small"
            />
            <TextField
              label="Area of Duty"
              value={formData.AreaOfDuty}
              onChange={(e) =>
                setFormData({ ...formData, AreaOfDuty: e.target.value })
              }
              fullWidth
              size="small"
            />
            <TextField
              label="Projects"
              value={formData.Projects}
              onChange={(e) =>
                setFormData({ ...formData, Projects: e.target.value })
              }
              fullWidth
              size="small"
            />
            <TextField
              label="Website"
              value={formData.Website}
              onChange={(e) =>
                setFormData({ ...formData, Website: e.target.value })
              }
              fullWidth
              size="small"
            />
            <TextField
              label="Contact Person"
              value={formData.ContactPerson}
              onChange={(e) =>
                setFormData({ ...formData, ContactPerson: e.target.value })
              }
              fullWidth
              size="small"
            />
            <TextField
              label="Telephone"
              value={formData.Telephone}
              onChange={(e) =>
                setFormData({ ...formData, Telephone: e.target.value })
              }
              fullWidth
              size="small"
            />
            <TextField
              label="Email"
              value={formData.Email}
              onChange={(e) =>
                setFormData({ ...formData, Email: e.target.value })
              }
              fullWidth
              size="small"
            />
            <TextField
              label="Address"
              value={formData.Address}
              onChange={(e) =>
                setFormData({ ...formData, Address: e.target.value })
              }
              fullWidth
              size="small"
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2, gap: 1 }}>
          <Button
            onClick={handleCloseDialog}
            sx={{
              textTransform: "none",
              borderRadius: 1,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              textTransform: "none",
              borderRadius: 1,
              px: 3,
            }}
          >
            {editingPartner ? "Save Changes" : "Create Partner"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Partners;
