import { Feature } from "ol";
import { Point } from "ol/geom";
import { Style, Icon } from "ol/style";
import { fromLonLat } from "ol/proj";

// Create marker style
export const createMarkerStyle = () => {
  return new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: "/images/marker.png", // Add a marker image to your public/images folder
      scale: 0.5,
    }),
  });
};

// Create marker feature
export const createMarker = (coordinates) => {
  const feature = new Feature({
    geometry: new Point(fromLonLat(coordinates)),
  });
  feature.setStyle(createMarkerStyle());
  return feature;
};

// Default map center (you can adjust these coordinates)
export const DEFAULT_CENTER = fromLonLat([36.8219, 1.2921]); // Nairobi coordinates
export const DEFAULT_ZOOM = 8;
