import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Alert,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OSM from "ol/source/OSM";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat, transformExtent } from "ol/proj";
import { Style, Circle, Fill, Stroke } from "ol/style";
import "ol/ol.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const dataLayers = [
  { name: "Agrovets", color: "#FF5733", endpoint: "agrovets" },
  { name: "Care Clubs", color: "#33FF57", endpoint: "careclubs" },
  { name: "Community Groups", color: "#3357FF", endpoint: "communitygroups" },
  { name: "Equine Owners", color: "#FF33F6", endpoint: "equineowners" },
  { name: "Farriers", color: "#33FFF6", endpoint: "farriers" },
  { name: "Practitioners", color: "#F6FF33", endpoint: "practitioners" },
];

const NewMap = () => {
  const navigate = useNavigate();
  const mapRef = useRef();
  const mapElement = useRef();
  const [map, setMap] = useState(null);
  const [mapData, setMapData] = useState({
    Title: "",
    Description: "",
    Category: "",
    Dataset: [],
    Style: {
      color: "#000000",
      weight: 1,
      opacity: 1,
      fillColor: "#000000",
      fillOpacity: 0.2,
    },
    LayerSettings: {
      visible: true,
      minZoom: 0,
      maxZoom: 18,
      label: "",
      popup: "",
    },
    Classification: "point",
    Column: "",
    Status: true,
  });
  const [layerData, setLayerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Initialize OpenLayers map
  useEffect(() => {
    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([0, 0]),
        zoom: 2,
      }),
    });

    setMap(initialMap);

    return () => {
      if (map) {
        map.setTarget(null);
      }
    };
  }, []);

  // Add this function to validate coordinates
  const isValidCoordinates = (item) => {
    return (
      item.Latitude != null &&
      item.Longitude != null &&
      !isNaN(item.Latitude) &&
      !isNaN(item.Longitude) &&
      Math.abs(item.Latitude) <= 90 &&
      Math.abs(item.Longitude) <= 180
    );
  };

  // Update map when layer data changes
  useEffect(() => {
    if (!map) return;

    // Remove existing vector layers
    map
      .getLayers()
      .getArray()
      .filter((layer) => layer instanceof VectorLayer)
      .forEach((layer) => map.removeLayer(layer));

    // Add new vector layers
    Object.entries(layerData).forEach(([datasetName, items]) => {
      const validItems = items.filter(isValidCoordinates);

      if (validItems.length === 0) return; // Skip if no valid coordinates

      const vectorSource = new VectorSource({
        features: validItems.map((item) => {
          const feature = new Feature({
            geometry: new Point(fromLonLat([item.Longitude, item.Latitude])),
            name: item.Name,
            dataset: datasetName,
            location: item.Location || item.Address,
          });
          return feature;
        }),
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: new Style({
          image: new Circle({
            radius: 6,
            fill: new Fill({
              color:
                dataLayers.find((l) => l.name === datasetName)?.color ||
                "#000000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 2,
            }),
          }),
        }),
      });

      map.addLayer(vectorLayer);
    });
  }, [map, layerData]);

  useEffect(() => {
    if (mapData.Dataset.length > 0) {
      fetchLayerData();
    }
  }, [mapData.Dataset]);

  const fetchLayerData = async () => {
    setLoading(true);
    try {
      const dataPromises = mapData.Dataset.map(async (dataset) => {
        const layerConfig = dataLayers.find((l) => l.name === dataset);
        if (!layerConfig) return { dataset, data: [] };

        try {
          const response = await fetch(`/api/${layerConfig.endpoint}/all/0`);

          if (!response.ok) {
            console.error(
              `Error fetching ${dataset} data:`,
              response.statusText
            );
            return { dataset, data: [] };
          }

          const data = await response.json();
          return { dataset, data: data.result || [] };
        } catch (error) {
          console.error(`Error fetching ${dataset} data:`, error);
          return { dataset, data: [] };
        }
      });

      const results = await Promise.all(dataPromises);
      const newLayerData = {};
      results.forEach(({ dataset, data }) => {
        if (data && data.length > 0) {
          newLayerData[dataset] = data;
        }
      });
      setLayerData(newLayerData);

      // Update map layers after fetching data
      updateMapLayers(newLayerData);
    } catch (error) {
      console.error("Error fetching layer data:", error);
      showSnackbar("Failed to fetch layer data", "error");
    } finally {
      setLoading(false);
    }
  };

  // Add function to update map layers
  const updateMapLayers = (layerData) => {
    if (!map) return;

    // Remove existing vector layers
    map
      .getLayers()
      .getArray()
      .filter((layer) => layer instanceof VectorLayer)
      .forEach((layer) => map.removeLayer(layer));

    // Add new vector layers
    Object.entries(layerData).forEach(([datasetName, items]) => {
      const validItems = items.filter(isValidCoordinates);

      if (validItems.length === 0) return;

      const vectorSource = new VectorSource({
        features: validItems.map((item) => {
          const feature = new Feature({
            geometry: new Point(fromLonLat([item.Longitude, item.Latitude])),
            name: item.Name,
            dataset: datasetName,
            location: item.Location || item.Address,
          });
          return feature;
        }),
      });

      const layerConfig = dataLayers.find((l) => l.name === datasetName);
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: new Style({
          image: new Circle({
            radius: 6,
            fill: new Fill({
              color: layerConfig?.color || "#000000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 2,
            }),
          }),
        }),
      });

      map.addLayer(vectorLayer);
    });

    // Fit view to show all features if there are any
    const allFeatures = Object.values(layerData)
      .flat()
      .filter(isValidCoordinates);

    if (allFeatures.length > 0) {
      const extent = [
        Math.min(...allFeatures.map((f) => f.Longitude)) - 0.1,
        Math.min(...allFeatures.map((f) => f.Latitude)) - 0.1,
        Math.max(...allFeatures.map((f) => f.Longitude)) + 0.1,
        Math.max(...allFeatures.map((f) => f.Latitude)) + 0.1,
      ];

      map.getView().fit(transformExtent(extent, "EPSG:4326", "EPSG:3857"), {
        padding: [50, 50, 50, 50],
        duration: 1000,
      });
    }
  };

  const handleDatasetChange = (event) => {
    const {
      target: { value },
    } = event;
    const newDatasets = typeof value === "string" ? value.split(",") : value;

    setMapData({
      ...mapData,
      Dataset: newDatasets,
    });

    // Clear layers if no datasets selected
    if (newDatasets.length === 0) {
      setLayerData({});
      updateMapLayers({});
    }
  };

  const handleSave = async () => {
    try {
      const gisData = {
        Title: mapData.Title,
        Category: mapData.Category,
        Description: mapData.Description,
        Dataset: mapData.Dataset.map((dataset) => {
          const config = dataLayers.find((l) => l.name === dataset);
          return config.endpoint;
        }).join(","),
        Style: JSON.stringify(mapData.Style),
        LayerSettings: JSON.stringify(mapData.LayerSettings),
        Status: true,
        URL: JSON.stringify(layerData),
        Thumbnail: `https://via.placeholder.com/300x200?text=${encodeURIComponent(
          mapData.Title
        )}`,
      };

      const response = await fetch("/api/gis/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(gisData),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to save map");
      }

      showSnackbar("Map saved successfully", "success");
      setTimeout(() => navigate("/gis/maps"), 1500);
    } catch (error) {
      console.error("Error saving map:", error);
      showSnackbar(error.message || "Failed to save map", "error");
    }
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Create New Map
          </Typography>

          <Stack spacing={3}>
            <TextField
              label="Map Title"
              value={mapData.Title}
              onChange={(e) =>
                setMapData({ ...mapData, Title: e.target.value })
              }
              fullWidth
              required
            />

            <TextField
              label="Description"
              value={mapData.Description}
              onChange={(e) =>
                setMapData({ ...mapData, Description: e.target.value })
              }
              multiline
              rows={3}
              fullWidth
            />

            <TextField
              label="Category"
              value={mapData.Category}
              onChange={(e) =>
                setMapData({ ...mapData, Category: e.target.value })
              }
              fullWidth
              required
            />

            <FormControl fullWidth>
              <InputLabel>Select Datasets</InputLabel>
              <Select
                multiple
                value={mapData.Dataset}
                onChange={handleDatasetChange}
                input={<OutlinedInput label="Select Datasets" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {dataLayers.map((layer) => (
                  <MenuItem key={layer.name} value={layer.name}>
                    <Checkbox
                      checked={mapData.Dataset.indexOf(layer.name) > -1}
                    />
                    <ListItemText primary={layer.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box
              ref={mapElement}
              sx={{
                height: 500,
                width: "100%",
                "& .ol-control": {
                  background: "rgba(255,255,255,0.4)",
                  padding: "3px",
                  borderRadius: "4px",
                },
                "& .ol-zoom": {
                  top: ".5em",
                  left: ".5em",
                },
              }}
            />

            <Button
              variant="contained"
              onClick={handleSave}
              disabled={
                !mapData.Title ||
                !mapData.Category ||
                mapData.Dataset.length === 0
              }
            >
              Save Map
            </Button>
          </Stack>
        </Box>
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewMap;
